button {
  height: 36px;
  width: 100%;
  font-weight: 500;
}

.formButton {
  margin: 10px 0px;
}

button:hover {
  filter: contrast(120%);
}
