p h3 {
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

.card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  transition: 0.2s ease-in;
  cursor: pointer;
  width: fit-content;
  z-index: 0;
  padding: 10px;
}

.image {
  height: 270px;
  min-width: 210px;
  border-radius: 12px;
  border: 1px solid #aaaaaa;
  transition: 0.2s ease-in;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.type {
  font-size: 12px;
  padding: 0;
  margin: 2px 0 0 0;
  color: #d3455b;
  font-weight: bold;
}

.title {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: #313343;
  font-weight: 500;
  line-height: 1.2;
  box-sizing: border-box;
}

.description {
  font-size: 12px;
  padding: 0;
  margin: 2px 0 0 0;
  color: #aaaaaa;
  font-weight: 500;
}

.card:hover {
  border-radius: 12px;
  -webkit-box-shadow: -2px 12px 17px -5px rgba(194, 194, 194, 1);
  -moz-box-shadow: -2px 12px 17px -5px rgba(194, 194, 194, 1);
  box-shadow: -2px 12px 17px -5px rgba(194, 194, 194, 1);
}

.info {
  margin-top: 7px;
}

@media only screen and (max-width: 600px) {
  .image {
    min-width: 175px;
    min-height: 190px;
  }
}
