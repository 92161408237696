@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Raleway:200,300,400,500,600,700,800);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h3 {
  font-weight: 600;
  color: #313343;
  margin-bottom: 20px;
}

.SignUp_container__2Dblk {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 100px;
}

.SignUp_header__3tdbh {
  display: flex;
  flex-direction: column;
}

.SignUp_form__3-wsa {
  display: flex;
  flex-direction: column;
  padding: 2em;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.SignUp_logo__A8QT2 {
  width: 250px;
  margin: 0px auto 20px;
}

.SignUp_error__3ptjC {
  font-family: "Open Sans", sans-serif;
  color: #cc0000;
}

button {
  height: 36px;
  width: 100%;
  font-weight: 500;
}

.Button_formButton__2BxWd {
  margin: 10px 0px;
}

button:hover {
  -webkit-filter: contrast(120%);
          filter: contrast(120%);
}

p h3 {
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

.Card_card__2F2sv {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  transition: 0.2s ease-in;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 0;
  padding: 10px;
}

.Card_image__3aAy1 {
  height: 270px;
  min-width: 210px;
  border-radius: 12px;
  border: 1px solid #aaaaaa;
  transition: 0.2s ease-in;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.Card_type__34SmJ {
  font-size: 12px;
  padding: 0;
  margin: 2px 0 0 0;
  color: #d3455b;
  font-weight: bold;
}

.Card_title__1J8Wo {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: #313343;
  font-weight: 500;
  line-height: 1.2;
  box-sizing: border-box;
}

.Card_description__hI2H9 {
  font-size: 12px;
  padding: 0;
  margin: 2px 0 0 0;
  color: #aaaaaa;
  font-weight: 500;
}

.Card_card__2F2sv:hover {
  border-radius: 12px;
  box-shadow: -2px 12px 17px -5px rgba(194, 194, 194, 1);
}

.Card_info__3NlCR {
  margin-top: 7px;
}

@media only screen and (max-width: 600px) {
  .Card_image__3aAy1 {
    min-width: 175px;
    min-height: 190px;
  }
}

.Carousel_fluid__1ZTSC {
  background-color: #f7f9ff;
  width: 100%;
}

.Carousel_images__RPJ-A {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  padding-top: 20px;
  align-self: center;
  cursor: -webkit-grab;
  cursor: grab;
  transition: 0.2s ease-in;
  padding: 20px 0px 0px 30px;
}

.Carousel_logo__15Q7V {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-self: center;
  height: 180px;
  width: 180px;
}

/* .images:hover {
  filter: grayscale(0%);
} */

@-webkit-keyframes Nav_menuMobileAnimation__28WsT {
  0% {
    opacity: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}

@keyframes Nav_menuMobileAnimation__28WsT {
  0% {
    opacity: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}

@-webkit-keyframes Nav_navOpacity__XB_7X {
  0% {
    height: 0px;
    opacity: 0;
  }

  100% {
    height: 70px;
    opacity: 1;
  }
}

@keyframes Nav_navOpacity__XB_7X {
  0% {
    height: 0px;
    opacity: 0;
  }

  100% {
    height: 70px;
    opacity: 1;
  }
}

html {
  scroll-behavior: smooth;
}

.Nav_nav__1Tbw4 {
  display: flex;
  flex-direction: column;
  height: 70px;
  align-content: center;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.Nav_left__3YGp7 {
  cursor: pointer;
}

.Nav_fixedNav__3a2q4 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  -webkit-animation-name: Nav_navOpacity__XB_7X;
          animation-name: Nav_navOpacity__XB_7X;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  z-index: 2;
}

.Nav_navrow__3dkCX {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}

.Nav_row__2C-Gi {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  transition: 1s ease-in;
}

.Nav_menuMobile__1_0B7 {
  margin-top: 100px;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: white;
  margin: auto;
  transition: 1s ease-in;
  -webkit-animation-name: Nav_menuMobileAnimation__28WsT;
          animation-name: Nav_menuMobileAnimation__28WsT;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
}

.Nav_right__1u7KJ {
  display: flex;
}

.Nav_menu__1I-ch {
  display: flex;
  list-style-type: none;
  color: black;
  font-size: 16px;
}

.Nav_menu__1I-ch a {
  color: black;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.Nav_menu__1I-ch a:hover {
  color: #2e9be2;
}

.Nav_menu__1I-ch:target {
  background-color: blue;
}

.Nav_burguer__N9Alz {
  color: #2e9be2;
  background-color: white;
  border: 1px solid #7070703a;
  height: 45px;
  width: 45px;
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .Nav_nav__1Tbw4 {
    padding: 15px;
    margin-top: 0;
    justify-content: unset;
  }

  .Nav_navrow__3dkCX {
    display: unset;
    width: 85%;
    transition: 1s ease-in;
    padding-bottom: 10px;
    z-index: 999;
    position: fixed;
  }

  .Nav_menu__1I-ch {
    display: flex;
    flex-direction: column;
  }
}

.TextInput_field__26r9y {
  width: 100%;
  font-size: 18px;
}

input {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #7070703a;
}

.TextInput_input__1ZNDx {
  display: flex;
  flex-direction: column;
}

.TextInput_select__333v9 {
  margin-top: 15px;
  padding: 10px 5px;
  border-radius: 3px;
  border: 1px solid #7070703a;
  width: 100%;
}

.TextInput_checkbox__2D-Sk {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 0 0;
}

.TextInput_checkfield__3DjyX {
  background-color: blue;
}

.TextInput_checkfieldText__2nSZ9 {
  font-size: 18px;
  margin: 0;
  padding: 0;
  margin-left: 10px;
  font-weight: 300;
  color: black;
}

.TextInput_label__10yP5 {
  font-size: 18px;
  margin-top: 7px;
  color: black;
  font-weight: 400;
}

.TextInput_error__1xwk_ {
  color: rgb(235, 20, 20);
  padding-top: 5px;
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (min-width: 600px) {
  .TextInput_container__dsCjv {
    width: 1024px;
    margin: 20px auto;
  }

  .TextInput_input__1ZNDx {
    width: 100%;
  }

  .TextInput_middleText__3a_Zg {
    width: 450px;
  }
}

.Spinner_load__hW0gq {
  width: 50%;
  font-size: 30px;
  margin: auto;
}
.Spinner_loader__-8_dn,
.Spinner_loader__-8_dn:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.Spinner_loader__-8_dn {
  margin: 30px auto;
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid #e93232;
  transform: translateZ(0);
  -webkit-animation: Spinner_load8__72vOH 1.1s infinite linear;
  animation: Spinner_load8__72vOH 1.1s infinite linear;
}
@-webkit-keyframes Spinner_load8__72vOH {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes Spinner_load8__72vOH {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Modal_modalButtons__wmiRQ {
  display: flex;
}

.Modal_buttons__2nax- {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.Modal_modal__35xss {
  z-index: 999;
  background-color: white;
  padding: 20px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  border: 1px solid #707070;
  border-radius: 5px;
}

.Modal_close__10OQt {
  width: 100%;
  text-align: right;
  top: 10px;
  right: 10px;
  position: absolute;
}

.Modal_close__10OQt i {
  color: rgb(97, 97, 97);
  padding: 10px 12px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.1s ease-in;
}

.Modal_close__10OQt i:hover {
  color: rgb(70, 69, 69);
  background-color: rgb(231, 231, 231);
  padding: 12px 14px;
  border-radius: 50%;
  cursor: pointer;
}

@media (max-width: 600px) {
  .Modal_modal__35xss {
    max-height: 600px;
    overflow-y: unset;
  }
}

.Toast_toast__3RZB- {
  box-shadow: 4px 2px 25px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  margin-top: 20px;
  padding: 20px;
  font-weight: 600;
}

.Login_container__3PcGN {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 100px;
}

.Login_card__3MKQX {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.Login_form__3mQ3_ {
  display: flex;
  min-width: 580px;
  flex-direction: column;
  padding: 2em;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.Login_logo__3IxLN {
  width: 250px;
  margin: 0px auto 20px;
}

@media only screen and (max-width: 600px) {
  .Login_form__3mQ3_ {
    min-width: unset;
  }
}

.EventInfo_detail__9iWfa {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.EventInfo_item__RzlyW {
  display: flex;
  margin-bottom: 15px;
  justify-content: flex-start;
  color: black;
}

.EventInfo_item__RzlyW i {
  margin-right: 15px;
  font-size: 36px;
  text-align: center;
  color: #212331;
}

.EventInfo_item__RzlyW p {
  margin-right: 35px;
  font-weight: 300;
  font-size: 16px;
  color: #212331;
}

@media only screen and (max-width: 600px) {
}

.EventApplication_currentCredits__3hRYh {
  font-weight: bold;
  font-size: 20px;
  color: black;
}

.EventApplication_availableCredits__1ySoS {
  font-weight: 300;
  font-size: 16px;
  color: black;
  margin-top: 10px;
}

.EventApplication_upperRow__1EwmK {
  border-bottom: 1px solid #7070703a;
}

.EventApplication_description__1Q7zG {
  font-weight: 300;
  color: #212331;
  font-size: 16px;
}

.EventApplication_description__1Q7zG {
  width: 60%;
  font-size: 16px;
}

.EventApplication_button__pFR6_ {
  width: 40%;
}

p {
  width: 100%;
  font-size: 16px;
  margin: 0;
}

.EventApplication_downRow__1nuq- {
  padding: 20px 0 0 0;
}

@-webkit-keyframes EventApplication_moveBox__aOU8f {
  0% {
    margin-top: 40px;
  }

  100% {
    margin-top: 50px;
  }
}

@keyframes EventApplication_moveBox__aOU8f {
  0% {
    margin-top: 40px;
  }

  100% {
    margin-top: 50px;
  }
}

.EventApplication_box__19IDA {
  margin-top: 40px;
  padding: 20px 15px;
  border: 1px solid #c6c6c6;
  transition: 1s ease-in;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.EventApplication_box__19IDA i {
  font-size: 20px;
  color: #e51d49;
}

.EventApplication_box__19IDA h4 {
  padding-left: 10px;
}

.EventApplication_box__19IDA:hover {
  -webkit-animation-name: EventApplication_moveBox__aOU8f;
          animation-name: EventApplication_moveBox__aOU8f;
  -webkit-animation-duration: 0.4s;
          animation-duration: 0.4s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.RequestEventModal_container__2NiWa {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  max-width: 600px;
}

.RequestEventModal_optionsList__e2PZ9 {
  margin: auto;
  padding: 10px;
  align-self: center;
  align-self: center;
  width: 50%;
}

.RequestEventModal_button__3lAXD {
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .RequestEventModal_header__65_AV {
    width: 300px;
    flex-direction: column;
    justify-items: center;
    text-align: center;
  }
  .RequestEventModal_list__3xkN8 {
    flex-direction: column;
  }

  .RequestEventModal_optionsList__e2PZ9 {
    display: flex;
    flex-direction: row;
    width: 50%;
  }
}


.Main_title__Ti3rH {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.Main_text__3TW-z {
  font-weight: 300;
  padding: 0 0 20px 0;
}

.Main_containerMobile__hlxLt {
  width: 100%;
  padding: 15px;
}

/*Table of Contents
/*Table of Contents
01 body
02 Helper Classes
   02-1 short codes
03 navigation
   03-1 side menu
04 owl carousel
05 revolution slider
06 half-section
07 page-header
08 work process
09 our team
10 gallery portfolio
11 mobile apps & features
12 Counters
13 pricings
14 background parallax
15 Testimonials
16 Partner
17 our blog
   17-1 sidebar
   17-2 widgets
18 Ccntact US
19 footer
20 preloader


/*Table Of Contents ends */

/*Global Styling*/
body {
  font-family: "Raleway", sans-serif;
  color: #a5a5a5;
}

/*common font family*/
p,
.heading-title > span,
.process-wrapp li > .pro-step,
.progress-bars .progress p,
.progress-bars .progress .progress-bar span,
.price-table .ammount .dur,
.pagination li > a,
.counters .count_nums,
.price-table .ammount h2,
.price-table ul li,
.webcats li a > span,
.getin_form .form-control {
  font-family: "Open Sans", sans-serif;
}

/* Helper Classes & Shorcodes */

.bglight {
  background: #f6f6f6;
}
.bgdefault {
  background: #00bcd4;
}
.whitecolor {
  color: #ffffff;
}
.darkcolor {
  color: #212331;
}
.extradark-color {
  color: #212331;
}
.defaultcolor {
  color: #00bcd4;
}
section {
  position: relative;
}
.gradient_bg {
  background: #643094;
}
.gradient_bg_default {
  background: #00bcd4;
}

/*heading Titles */
.heading-title > span {
  color: #00bcd4;
}
.whitecolor.heading-title > span {
  color: #fff;
}

/*hover on images*/
.hover-effect::before,
.hover-effect::after {
  background: #fff;
}

/*Back To Top*/
.back-top {
  color: #fff;
  background: #643094;
}
.back-top::before {
  background: #00bcd4;
}
.back-top:hover,
.back-top:focus {
  color: #fff;
}

/* ----- Social Icons ----- */
ul.social-icons li a {
  color: #676767;
}
ul.social-icons.white li a,
ul.social-icons-simple.white li a {
  color: #fff;
}
ul.social-icons-simple li a:hover,
ul.social-icons-simple li a:focus {
  color: #00bcd4;
}
ul.social-icons li a:hover,
ul.social-icons li a:focus,
ul.social-icons.white li a:hover,
ul.social-icons.white li a:focus {
  color: #fff;
  box-shadow: 0 0 15px 30px #00bcd4 inset;
}

/*-----Buttons-----*/
.btnprimary,
.btnprimary-alt {
  background: #00bcd4;
}
.btnsecondary {
  background: #643094;
}
.btnwhite {
  background: #fff;
  color: #212331;
}
.btnwhite-hole {
  background: transparent;
  border: 1px solid #fff;
}
.btnwhite:hover,
.btnwhite:focus {
  background: #00bcd4;
}

.btnwhite-hole:hover,
.btnwhite-hole:focus {
  color: #212331;
  background: #fff;
}
.btnprimary:hover,
.btnprimary:focus {
  background: #643094;
  border: 1px solid #643094;
}
.btnprimary-alt:hover,
.btnprimary-alt:focus {
  background: transparent;
  border: 1px solid #00bcd4;
  color: #00bcd4;
}
.rev_slider .btnprimary-alt:hover,
.rev_slider .btnprimary-alt:focus {
  background: transparent;
  border: 1px solid #fff;
}

.btnsecondary:hover,
.btnsecondary:focus {
  background: #00bcd4;
  border: 1px solid #00bcd4;
}
.btnsecondary.hvrwhite:hover,
.btnsecondary.hvrwhite:focus,
.btnprimary.hvrwhite:hover,
.btnprimary.hvrwhite:focus {
  background: #fff;
  border: 1px solid #fff;
  color: #212331;
}

.btn-gradient-hvr::before {
  background: linear-gradient(90deg, #423f9c, #862359);
}
.scndry-gradient-hvr::before {
  background: linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
}
.btn-gradient-hvr:hover,
.btn-gradient-hvr:focus {
  border-color: #643094;
}
.scndry-gradient-hvr:hover,
.scndry-gradient-hvr:focus {
  border-color: #00bcd4;
}

.btnwhite-hole,
.btnwhite:hover,
.btnwhite:focus,
.btnprimary,
.btnsecondary,
.btnprimary-alt,
.btnprimary:hover,
.btnprimary:focus,
.btnsecondary:hover,
.btnsecondary:focus,
.rev_slider .btnprimary-alt:hover,
.rev_slider .btnprimary-alt:focus {
  color: #fff;
}

/*-------------------------------*/
/*Navigation Starts */
/*-------------------------------*/
.bg-white {
  background: #fff;
}
.fixedmenu {
  background: #00bcd4;
}
.center-brand.fixedmenu,
.bg-white.fixedmenu,
.bg-transparent-light.fixedmenu,
.bg-transparent-white.fixedmenu {
  background: #fff;
}
.nav-whitebg {
  background: #fff;
}
.navbar-nav .nav-link {
  color: #212331;
}
.center-brand .navbar-nav .nav-link,
.transparent-bg .navbar-nav .nav-link,
.fixed-bottom .navbar-nav .nav-link {
  color: #fff;
}
.center-brand.fixedmenu .navbar-nav .nav-link {
  color: #212331;
}
.center-brand .navbar-nav .nav-link:hover,
.transparent-bg .navbar-nav .nav-link:hover,
.fixed-bottom .navbar-nav .nav-link:hover {
  color: #00bcd4;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.active:hover,
.center-brand.fixedmenu .navbar-nav .nav-link.active {
  background: #00bcd4;
  color: #fff;
}
.center-brand .navbar-nav .nav-link:first-of-type {
  background: rgba(0, 0, 0, 0);
}

/*toggle responsive*/
.navbar-toggler span {
  background: #fff;
}
.bg-white .navbar-toggler span {
  background: #212331;
}
.center-brand.fixedmenu .navbar-toggler span {
  background: #00bcd4;
}
.navbar-toggler:not(.collapsed) span:nth-child(2) {
  background-color: transparent;
}

/*-----Side Menu----*/
.side-menu {
  background: #00bcd4;
}
.just-sidemenu .side-menu {
  background: #643094;
}
.transparent-sidemenu .side-menu {
  background: rgba(0, 0, 0, 0.95);
}
.side-menu.side-menu-active {
  transform: translate3d(0, 0, 0);
}

/*Side overlay*/
#close_side_menu {
  background-color: #000;
}

/*side clode btn*/
.side-menu .btn-close::before,
.side-menu .btn-close::after {
  background: #fff;
}

/*side open btn*/
.sidemenu_btn > span {
  background: #212331;
}
.center-brand .sidemenu_btn > span,
.transparent-bg .sidemenu_btn > span,
.fixed-bottom .sidemenu_btn > span,
.just-sidemenu .toggle_white.sidemenu_btn > span {
  background: #fff;
}
.center-brand.fixedmenu .sidemenu_btn > span {
  background: #212331;
}
.side-nav .navbar-nav .nav-link {
  color: #fff;
}
.side-nav .navbar-nav .nav-link::after {
  background: #fff;
}
.side-nav .navbar-nav .nav-link.active {
  background: transparent;
}

@media (max-width: 992px) {
  .center-brand .navbar-nav .nav-link,
  .transparent-bg .navbar-nav .nav-link {
    background: #00bcd4;
  }
  .center-brand.fixedmenu .navbar-nav .nav-link,
  .transparent-bg.fixedmenu .navbar-nav .nav-link {
    background: transparent;
  }
}

/*-------------------------------*/
/*Navigation Ends */
/*-------------------------------*/

/*-------------------------------*/
/*OWl Slider*/
/*-------------------------------*/
/*Dots*/
.owl-dots .owl-dot {
  background: rgba(255, 255, 255, 0.35);
}
#text-fading .owl-dots .owl-dot {
  background: #fff;
}
.vertical-dot .owl-dots .owl-dot {
  background: rgba(255, 255, 255, 0.5);
}

.owl-dots .owl-dot::after {
  background-color: #00bcd4;
}
.vertical-dot .owl-dots .owl-dot.active {
  background: #fff;
}

/*Buttons*/
.owl-nav .owl-prev,
.owl-nav .owl-next {
  background: #00bcd4;
  color: #fff;
}
.owl-nav .owl-prev:hover,
.owl-nav .owl-next:hover,
.owl-nav .owl-prev:focus,
.owl-nav .owl-next:focus {
  background: #643094;
}

/*Service Slider*/
#services-slider .service-box {
  background: #00bcd4;
  color: #fff;
}
#services-slider .service-box::before {
  background: #00bcd4;
}
#services-slider .owl-item.center .service-box::before {
  background: #643094;
}
#services-slider .owl-item.center .service-box {
  background: #643094;
}

/*-------------------------------*/
/*OWL Slider ends*/
/*-------------------------------*/

/*-------------------------------*/
/*Revolution SLider*/
/*-------------------------------*/
.tp-bullet {
  background: rgba(255, 255, 255, 0.3);
}
.tp-bullet:hover,
.tp-bullet:focus {
  background: rgba(255, 255, 255, 0.6);
}
.tp-bullet::before {
  background: #fff;
}

.uranus .tp-bullet-inner {
  background: #00bcd4;
}
.uranus .tp-bullet.selected,
.uranus .tp-bullet:hover {
  box-shadow: 0 0 0 2px #643094;
}
.uranus .tp-bullet.selected .tp-bullet-inner,
.uranus .tp-bullet:hover .tp-bullet-inner {
  background: #643094;
}

.rev_slider li.rev_gradient::after {
  background: #643094;
}
.banner-overlay::after {
  background: rgba(0, 0, 0, 0.39);
}

/*-------------------------------*/
/* Main Banner Ends*/
/*-------------------------------*/

/*-------------------------------*/
/* Horizontal Half Blocks*/
/*-------------------------------*/
.half-section .img-container {
}

.breadcrumb {
  background: transparent;
}
.breadcrumb .breadcrumb-item {
  color: #fff;
}
.breadcrumb .breadcrumb-item:hover,
.breadcrumb .breadcrumb-item:focus,
.breadcrumb .breadcrumb-item.active {
  color: #00bcd4;
}

/*-------------------------------*/
/*Page Headers */
/*-------------------------------*/

/*-------------------------------*/
/* Work Process */
/*-------------------------------*/
.process-wrapp li > .pro-step {
  border: 1px solid #fff;
  color: #fff;
}
.process-wrapp li:hover > .pro-step {
  box-shadow: 0 0 25px 50px #fff inset;
  color: #00bcd4;
}
.process-wrapp li::before,
.process-wrapp li::after {
  background: rgba(255, 255, 255, 0.5);
}

/*process with box */
.process-number {
  background: #fff;
}
.process-number .pro-step {
  color: #fff;
  background: #d2d2d2;
}
.process-number .pro-step::after {
  background: #fff;
}

/*-------------------------------*/
/*Work Process ends */
/*-------------------------------*/

/*-------------------------------*/
/* Our Team */
/*-------------------------------*/
.team-box.grey-shade {
  box-shadow: 0px 0px 0px 1px #b5b5b5;
}
.team-box::before {
  border: 20px solid #fff;
}

.team-box.no-hover .team-content {
  box-shadow: 0px 10px 5px -10px #b7b7b7;
  background: #fff;
}
.team-box.no-hover .team-content h3 {
  color: #212331;
}
.team-box.no-hover .team-content::before {
  background: #643094;
}
.team-box.no-hover:hover .team-content::before,
.team-box.no-hover:hover .team-content,
.team-box.no-hover:hover .team-content h3,
.team-box.no-hover:hover .team-content ul.social-icons li a {
  color: #fff;
}

/*Progress Bars*/
.progress-bars .progress .progress-bar {
  background: linear-gradient(90deg, #f6663f 31%, #ed145b 69%);
}

/*-------------------------------*/
/*Our Team ends */
/*-------------------------------*/

/*-------------------------------*/
/* Gallery Portfolio */
/*-------------------------------*/
/*filters*/
.cbp-l-filters .cbp-filter-item span {
  color: #b7b7b7;
  border: 1px solid #b7b7b7;
}
.cbp-l-filters .cbp-filter-item:hover span {
  border: 1px solid #643094;
  color: #fff;
  background: #643094;
}
.cbp-l-filters .cbp-filter-item-active span,
.cbp-l-filters .cbp-filter-item:focus span {
  border: 1px solid #00bcd4;
  color: #fff;
  background: #00bcd4;
}
#portfolio_top::before,
#portfolio_top::after {
  background: #fff;
}
.border-portfolio .cbp-item img {
  border: 20px solid #fff;
}
.bottom-text .port_head {
  font-family: "Open Sans", sans-serif;
}

/*Overlays*/
.overlay {
  background: rgba(0, 207, 204, 0.9);
}
.dark_overlay .overlay {
  background: rgba(33, 35, 49, 0.9);
}
.overlay > .plus::before,
.overlay > .plus::after {
  background: #fff;
}
.gradient_text {
  color: #00abc9;
}

/*-------------------------------*/
/*Gallery ends */
/*-------------------------------*/

/*-------------------------------*/
/* Mobile Apps & Features */
/*-------------------------------*/

/*apps background*/

/*app features*/
.feature-item h4 {
  color: #222222;
}
.feature-item .icon {
  background: #00bcd4;
  color: #fff;
}
.feature-item .icon::before {
  background: transparent;
  border: 4px solid #fff;
}

#app-feature .feature-item:hover .icon {
  background: #643094;
}

.feature-item.active {
  background: #f7f7f7;
}

/*-------------------------------*/
/*Mobile Apps ends */
/*-------------------------------*/

/*-------------------------------*/
/* Counters */
/*-------------------------------*/

.fact-iconic h3::after {
  background: #ffffff;
}
.bg-counter-light .icon-counters:hover .img-icon {
  color: #fff;
  background: #00bcd4;
}

/*-------------------------------*/
/*Counters ends */
/*-------------------------------*/

/*-------------------------------*/
/* Pricings */
/*-------------------------------*/

.pricing-bg::before {
  background: #f6f6f6;
}
.price-table {
  border: 1px solid #f6f3f3;
  background: #fff;
}
.price-table .ammount .dur {
  color: #545661;
}

.price-table.active .ammount h2 {
  color: #643094;
}
.price-table ul li {
  color: #6e6e6e;
}
.price-table ul li.not-support {
  color: #9b9b9b;
}
.price-table ul li > span::before {
  color: #30e512;
}
.price-table ul li.not-support > span::before {
  color: #ff434b;
}
.centered-table .price-table ul li > span::after {
  background: #f6f6f6;
}

.price-table:hover {
  background: #00bcd4;
  border-color: #00bcd4;
}
.price-table.active:hover {
  background: #643094;
  border-color: #643094;
}
.price-table:hover *,
.price-table:hover .ammount *,
.price-table:hover .ammount h2,
.price-table:hover .ammount .dur,
.price-table:hover ul li > span::before,
.price-table:hover .btnsecondary,
.price-table:focus .btnsecondary,
.price-table:hover .btnprimary,
.price-table:focus .btnprimary {
  color: #fff;
}
.price-table:hover .btnsecondary,
.price-table:focus .btnsecondary,
.price-table:hover .btnprimary,
.price-table:focus .btnprimary {
  border-color: #fff;
  background: transparent;
}
.price-table .btnsecondary:hover,
.price-table .btnsecondary:focus,
.price-table .btnprimary:hover,
.price-table .btnprimary:focus {
  border-color: #fff;
  background: #fff;
  color: #212331;
}

/*-------------------------------*/
/* Pricings ends */
/*-------------------------------*/

/*-------------------------------*/
/*Backgrounds Parallax */
/*-------------------------------*/

/*banner parallax*/

.button-play {
  color: #fff;
  background: #643094;
}
.button-play::before {
  background: #00bcd4;
}
.button-play:hover,
.button-play:focus {
  color: #fff;
}

/*Background Video*/
.bg-video-container::before {
  background: rgba(33, 35, 49, 0.7);
}

/*-------------------------------*/
/*Backgrounds Parallax ends */
/*-------------------------------*/

/*-------------------------------*/
/* Testimonials*/
/*-------------------------------*/
.testimonial-wrapp .quoted {
  background: #643094;
  color: #fff;
}
.testimonial-wrapp .testimonial-text {
  background: #f6f6f6;
  border: 1px solid #f1f1f1;
}
#testimonial-slider .owl-item:nth-child(2n) .testimonial-wrapp .quoted,
#testimonial-slider
  .owl-item:nth-child(2n)
  .testimonial-wrapp:hover
  .testimonial-text {
  background: #00bcd4;
}
.testimonial-wrapp:hover .testimonial-text {
  background: #643094;
  color: #fff;
}

/*Testimonial Quotes*/

#owl-thumbs.owl-dots .owl-dot {
  background: transparent;
  border: 5px solid rgba(255, 255, 255, 0.3);
}
.testimonial-bg-light #owl-thumbs.owl-dots .owl-dot {
  border: 5px solid rgba(0, 0, 0, 0.3);
}
#owl-thumbs.owl-dots .owl-dot:hover,
#owl-thumbs.owl-dots .owl-dot.active {
  border: 5px solid rgba(255, 255, 255, 0.53);
}
.testimonial-bg-light #owl-thumbs.owl-dots .owl-dot.active {
  border: 5px solid rgba(0, 0, 0, 0.53);
}

/*-------------------------------*/
/* Testimonials ends*/
/*-------------------------------*/

/*-------------------------------*/
/* Partner/ Logo's*/
/*-------------------------------*/

/*-------------------------------*/
/* Partner/ Logo's ends*/
/*-------------------------------*/

/*-------------------------------*/
/* Our Blog*/
/*-------------------------------*/
.news_item {
  background: #fff;
}

.meta-tags li a {
  color: #a5a5a5;
}
.news_item:hover h3,
.meta-tags li a:hover,
.meta-tags li a:focus {
  color: #00bcd4;
}

/*Pagination*/
.pagination li > a {
  color: #a5a5a5;
}
.pagination li > a:hover,
.pagination li > a:focus {
  background: #00bcd4;
}
.pagination li > a:hover,
.pagination li > a:focus,
.pagination li.active > a {
  color: #fff;
}
.pagination li.active > a {
  background: #643094 !important;
}

blockquote.blockquote::before {
  color: #a1a1a1;
}
ul.rounded li::before {
  background: #00bcd4;
}

/*Post Comments*/
.eny_profile {
  border-bottom: 1px solid #e4e4e4;
}

/*Widget Newlatter or search*/
.widget_search .form-control,
.widget_search .input-group-addon {
  border-color: #d6d5d5;
}
.widget_search .input-group-addon {
  background-color: #00bcd4;
  color: #fff;
}
.widget_search .form-control:focus {
  border: 1px solid #414141;
}

/*web Tags*/
.webtags li a,
.btn_reply {
  color: #6a6a6a;
  border: 1px solid #c7c7c7;
}
.webtags li a:hover,
.webtags li a:focus {
  color: #fff;
  background: #00bcd4;
  border: 1px solid #00bcd4;
}
/*Recent Sidebar*/
.single_post:hover a,
.single_post:focus a {
  color: #00bcd4;
}

/*Categories*/
.webcats li a::before {
  background: #00bcd4;
}
.webcats li a:hover,
.webcats li a:focus {
  color: #00bcd4;
}
.whitebox .widget {
  background: #fff;
}

/*-------------------------------*/
/* Our Blog ends*/
/*-------------------------------*/

/*-------------------------------*/
/* Ccntact US*/
/*-------------------------------*/
.our-address h5 {
  color: #222;
}
.our-address .pickus {
  text-transform: uppercase;
  color: #00bcd4;
}
.our-address .pickus::after {
  color: #212331;
}
.our-address .pickus:hover,
.our-address .pickus:focus {
  color: #212331;
}

/*Contact Form*/
.getin_form .form-control {
  border-bottom: 1px solid #a5a5a5;
  background: #fff;
  color: #a5a5a5;
}
.getin_form .form-control:focus {
  border-bottom: 1px solid #212331;
}

/* Form general- */
::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #a5a5a5;
}
:-moz-placeholder,
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #a5a5a5;
}
::-moz-placeholder,
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #a5a5a5;
}
:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #a5a5a5;
}
textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder {
  color: #a5a5a5;
}

/*-------------------------------*/
/*  COntact US */
/*-------------------------------*/

/*-------------------------------*/
/* Footer*/
/*-------------------------------*/

footer ul.social-icons:not(.small) li a {
  background: transparent;
}
footer ul.social-icons li a {
  background: #f5f5f5;
}
footer .copyrights > a:hover,
footer .copyrights > a:focus {
  color: #00bcd4;
}

/*-------------------------------*/
/* Footer ends*/
/*-------------------------------*/

/*-------------------------------*/
/*PreLoader*/
/*-------------------------------*/
.loader {
  background: #fff;
}
.loader span {
  background: #00bcd4;
}

/*-------------------------------*/
/*Loader ends*/
/*-------------------------------*/

/*Table of Contents
/*Table of Contents
01 body
02 Helper Classes
   02-1 short codes
03 navigation
   03-1 side menu
04 owl carousel
05 revolution slider
06 half-section
07 page-header
08 work process
09 our team
10 gallery portfolio
11 mobile apps & features
12 Counters
13 pricings
14 background parallax
15 Testimonials
16 Partner
17 our blog
   17-1 sidebar
   17-2 widgets
18 Ccntact US
19 404 error
20 Accordions
21 footer
22 preloader



/*Table Of Contents ends */

/*Global Styling*/
body {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  color: #a5a5a5;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
body.full-page {
  overflow: hidden !important;
}

.fullscreen {
  height: 10%;
}

html {
  font-size: 16px;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a,
a:hover,
a:focus {
  outline: none;
  text-decoration: none;
  color: inherit;
}
input:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
h1 {
  font-size: 2.8rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.6rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 0.875rem;
}
p {
  font-size: 14px;
}
p.title {
  font-size: 1.25rem;
  font-weight: 300;
}
.block {
  display: block;
}
.italic {
  font-style: italic;
}
button {
  line-height: 1;
}

/*common font family*/
p,
.heading-title > span,
.process-wrapp li > .pro-step,
.progress-bars .progress p,
.progress-bars .progress .progress-bar span,
.price-table .ammount .dur,
.pagination li > a,
.counters .count_nums,
.price-table .ammount h2,
.price-table ul li,
.webcats li a > span,
.getin_form .form-control {
  font-family: "Open Sans", sans-serif;
}

/*fontAwesome*/
.zeus.tparrows.tp-rightarrow::before,
.zeus.tparrows.tp-leftarrow::before,
.price-table ul li > span::before,
.testimonial-quote h3::before,
.testimonial-quote h3::after,
blockquote.blockquote::before {
  font-family: "FontAwesome";
}

/* Helper Classes & Shorcodes */
.padding {
  padding: 8rem 0;
}
.padding_medium {
  padding: 6rem 0;
}
.padding_top {
  padding: 8rem 0 0 0;
}
.padding_top_medium {
  padding: 6rem 0 0 0;
}
.padding_mini {
  padding: 2rem 0;
}
.padding_acompanhamento {
  padding-top: 4rem;
  padding-bottom: 4rem;
  /*  padding-left: 2rem;*/
}
.padding_acompanhamento_left {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 2rem;
}
.padding_acompanhamento_right {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-right: 2rem;
  text-align: right;
}
@media (max-width: 550px) {
  .padding_acompanhamento {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .padding_acompanhamento_left {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .padding_acompanhamento_right {
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;
  }
  .padding_mini {
    padding: 0.5rem 0;
  }
}

.padding_main_banner {
  padding: 3rem 0;
}
.padding_midia {
  padding: 4rem 0;
}
.padding_top {
  padding-top: 8rem;
}
.padding_bottom {
  padding-bottom: 5.5rem;
}
.padding_bottom_mini {
  padding-bottom: 2.5rem;
}
.padding_bottom_micro {
  padding-bottom: 1rem;
}
.margin_bottom {
  margin-bottom: 7.5rem;
}
.margin_bottom_micro {
  margin-bottom: 1rem;
}
.margin_top {
  margin-top: 7.5rem;
}
.padding_half {
  padding: 4.375rem 0;
}
.margin_half {
  margin: 4.375rem 0;
}
.padding_bottom_half {
  padding-bottom: 4.375rem;
}
.margin_bottom_half {
  margin-bottom: 4.375rem;
}
.padding_top_half {
  padding-top: 4.375rem;
}
.heading_space {
  margin-bottom: 4.125rem;
}
.bottom5 {
  margin-bottom: 5px;
}
.top5 {
  margin-top: 5px;
}
.left5 {
  margin-left: 5px;
}
.right5 {
  margin-right: 5px;
}
.bottom10 {
  margin-bottom: 10px;
}
.top10 {
  margin-top: 10px;
}
.left10 {
  margin-left: 10px;
}
.right10 {
  margin-right: 10px;
}
.bottom15 {
  margin-bottom: 15px;
}
.top15 {
  margin-top: 15px;
}
.top20 {
  margin-top: 20px;
}
.bottom20 {
  margin-bottom: 20px;
}
.bottom25 {
  margin-bottom: 25px;
}
.top25 {
  margin-top: 25px;
}
.bottom30 {
  margin-bottom: 30px;
}
.top30 {
  margin-top: 30px;
}
.bottom35 {
  margin-bottom: 35px;
}
.top40 {
  margin-top: 40px;
}
.bottom40 {
  margin-bottom: 40px;
}
.bottom45 {
  margin-bottom: 45px;
}
.top50 {
  margin-top: 50px;
}
.top60 {
  margin-top: 60px;
}
.bottom60 {
  margin-bottom: 60px;
}
.bottom0 {
  margin-bottom: 0;
}
.nomargin {
  margin: 0;
}
.nopadding {
  padding: 0;
}
.fontbold {
  font-weight: bold;
}
.fontmedium {
  font-weight: 500;
}
.font-light {
  font-weight: 300;
}
.font-xlight {
  font-weight: 100;
}
.bglight {
  background: #f6f6f6;
}
.bgdefault {
  background: #2e9be2;
}
.whitecolor {
  color: #ffffff;
}
.gray {
  color: #777777;
}
.darkcolor {
  color: #212331;
}
.pinkcolor {
  color: #e51d49;
}
.lightbluecolor {
  color: #01cfff;
}
.darkbluecolor {
  color: #2e9be2;
}
.lightgraycolor {
  color: #c6c6c6;
}
.aaagray {
  color: #aaaaaa;
}
.extradark-color {
  color: #212331;
}
.defaultcolor {
  color: #2e9be2;
}

section {
  position: relative;
}
.container-padding {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
.gradient_bg {
  background: #3cb2df;
}

.gradient_bg_gray {
  background: #eeeeee;
}

.gradient_bg_default {
  background: linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
}

/*heading Titles */
.heading-title > span {
  font-size: 1.25rem;
  display: block;
  /*    text-transform: capitalize;*/
  color: #00bcd4;
}
.whitecolor.heading-title > span {
  color: #fff;
}
.heading-title h2 {
  font-weight: 300;
}

/*img wrap*/
.image {
  overflow: hidden;
}
a.image {
  display: block;
}
.image img {
  width: 100%;
  display: block;
}
.image,
.image img {
  position: relative;
}

/*hover on images*/
.hover-effect::before,
.hover-effect::after {
  content: "";
  background: #fff;
  height: 0;
  width: 0;
  z-index: 1;
  position: absolute;
  transition-duration: 1.3s;
}
.hover-effect::before {
  right: 0;
  opacity: 1;
  top: 0;
}
.hover-effect::after {
  bottom: 0;
  opacity: 0.7;
  left: 0;
}
.hover-effect:hover::after,
.hover-effect:hover::before {
  height: 100%;
  opacity: 0;
  width: 100%;
}

/*to align elements center*/
.center-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
/*Back To Top*/
/*.back-top {
   color: #fff;
   right: 20px;
   font-size: 26px;
   position: fixed;
   z-index: 1600;
   opacity: 0;
   visibility: hidden;
   bottom: 70px;
   background: -webkit-linear-gradient(90deg, #423f9c, #862359);
   background: -webkit-gradient(linear, left top, right top, color-stop(#423f9c), color-stop(#862359));
   background: -webkit-linear-gradient(left, #423f9c, #862359);
   background: -o-linear-gradient(left, #423f9c, #862359);
   background: -webkit-gradient(linear, left top, right top, from(#423f9c), to(#862359));
   background: linear-gradient(90deg, #423f9c, #862359);
   -webkit-transform: scale(0);
   -ms-transform: scale(0);
   -o-transform: scale(0);
   transform: scale(0);
   height: 40px;
   width: 40px;
   text-align: center;
   line-height: 36px;
   -webkit-border-radius: 50%;
   border-radius: 50%;
}
.back-top-visible {
   -webkit-transform: scale(1);
   -ms-transform: scale(1);
   -o-transform: scale(1);
   transform: scale(1);
   opacity: 1;
   visibility: visible;
}
.back-top::before {
   content: "";
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   position: absolute;
   z-index: -1;
   -webkit-border-radius: 50%;
   border-radius: 50%;
   opacity: 0;
   visibility: hidden;
   background: -webkit-linear-gradient(90deg, #643094, #00bcd4);
   background: -webkit-gradient(linear, left top, right top, color-stop(#643094), color-stop(#00bcd4));
   background: -webkit-linear-gradient(left, #643094, #00bcd4);
   background: -o-linear-gradient(left, #643094, #00bcd4);
   background: -webkit-gradient(linear, left top, right top, from(#643094), to(#00bcd4));
   background: linear-gradient(90deg, #643094, #00bcd4);
}
.back-top:hover::before, .back-top:focus::before {
   opacity: 1;
   visibility: visible;
}
.back-top:hover, .back-top:focus {
   color: #fff;
}*/

/* ----- Social Icons ----- */
ul.social-icons-simple li,
ul.social-icons li {
  display: inline-block;
}
ul.social-icons li a {
  height: 36px;
  line-height: 36px;
  width: 36px;
  font-size: 17px;
  border-radius: 50%;
  margin: 0 0.3rem;
  text-align: center;
  display: block;
  color: #676767;
}
ul.social-icons-simple li a {
  height: 24px;
  line-height: 24px;
  width: 24px;
  margin: 0 8px;
  font-size: 18px;
  text-align: center;
  display: inline-block;
}
ul.social-icons.white li a,
ul.social-icons-simple.white li a {
  color: #fff;
}
ul.social-icons-simple li:last-child a,
ul.social-icons li:last-child a {
  margin-right: 0;
}
ul.social-icons-simple li:first-child a,
ul.social-icons li:first-child a {
  margin-left: 0;
}
ul.social-icons-simple li a:hover,
ul.social-icons-simple li a:focus {
  color: #00bcd4;
}
ul.social-icons li a:hover,
ul.social-icons li a:focus,
ul.social-icons.white li a:hover,
ul.social-icons.white li a:focus {
  color: #fff;
  box-shadow: 0 0 15px 30px #00bcd4 inset;
}

.image-modulo {
  width: 200px;
}

.image-benefit {
  width: 160px;
}

/*-----Buttons-----*/
.button {
  position: relative;
  display: inline-block;
  font-size: 1.1rem;
  padding: 0.9rem 1rem;
  line-height: 1;
  text-align: center;
  font-weight: medium;
  z-index: 1;
  border-radius: 30px;
  overflow: hidden;
}

.btnprimary,
.btnprimary-alt {
  background: #00bcd4;
}
.btnsecondary {
  background: #643094;
}
.btnsecondary_pink {
  background: #e61748 !important;
}
.btnwhite {
  background: #fff;
  color: #212331;
}
.btnwhite-hole {
  background: transparent;
  border: 1px solid #fff;
}
.btnwhite:hover,
.btnwhite:focus {
  background: #00bcd4;
}

.btnwhite-hole:hover,
.btnwhite-hole:focus {
  color: #212331;
  background: #fff;
}
.button > i {
  vertical-align: middle;
  margin-top: -3px;
}
.btnprimary:hover,
.btnprimary:focus {
  background: #643094;
  border: 1px solid #643094;
}
.btnprimary-alt:hover,
.btnprimary-alt:focus {
  background: transparent;
  border: 1px solid #00bcd4;
  color: #00bcd4;
}
.rev_slider .btnprimary-alt:hover,
.rev_slider .btnprimary-alt:focus {
  background: transparent;
  border: 1px solid #fff;
}

.btnsecondary:hover,
.btnsecondary:focus {
  background: #00bcd4;
  border: 1px solid #00bcd4;
}
.btnsecondary.hvrwhite:hover,
.btnsecondary.hvrwhite:focus,
.btnprimary.hvrwhite:hover,
.btnprimary.hvrwhite:focus {
  background: #fff;
  border: 1px solid #fff;
  color: #212331;
}

.btn-gradient-hvr::before {
  background: linear-gradient(90deg, #423f9c, #862359);
}
.btn-gradient-hvr::before,
.scndry-gradient-hvr::before {
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.scndry-gradient-hvr::before {
  background: linear-gradient(90deg, #00e4ce 31%, #00abc9 69%);
}
.btn-gradient-hvr:hover::before,
.btn-gradient-hvr:focus::before,
.scndry-gradient-hvr:hover::before,
.scndry-gradient-hvr:focus::before {
  opacity: 1;
  visibility: visible;
}
.btn-gradient-hvr:hover,
.btn-gradient-hvr:focus {
  border-color: #643094;
}
.scndry-gradient-hvr:hover,
.scndry-gradient-hvr:focus {
  border-color: #00bcd4;
}

.btnwhite-hole,
.btnwhite:hover,
.btnwhite:focus,
.btnprimary,
.btnsecondary,
.btnprimary-alt,
.btnprimary:hover,
.btnprimary:focus,
.btnsecondary:hover,
.btnsecondary:focus,
.rev_slider .btnprimary-alt:hover,
.rev_slider .btnprimary-alt:focus {
  color: #fff;
}

/* transitions common*/
a,
*::before,
*::after,
img,
span,
input,
button,
.navbar,
.fixedmenu,
.tp-bullet,
.owl-dot,
.owl-prev,
.owl-next {
  transition: all 0.3s ease;
}
i::before,
i::after {
  transition: all 0s !important;
}

/*-------------------------------*/
/*Navigation Starts */
/*-------------------------------*/
.navbar {
  padding-bottom: 0;
  padding-top: 0;
  z-index: 1000;
}

.center-brand {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.center-brand,
.transparent-bg {
  left: 0;
  top: 0;
}
.center-brand,
.transparent-bg,
.fixed-bottom,
.bg-transparent-light,
.bg-transparent-white,
.bg-invisible {
  position: absolute;
  width: 100%;
}
.bg-invisible .container {
  opacity: 0;
  visibility: hidden;
}
.fixed-bottom {
  bottom: 0;
}
.bg-white {
  background: #fff;
  position: relative;
}
.fixedmenu {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
  z-index: 1002;
  -webkit-animation-name: animationFade;
  animation-name: animationFade;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  background: linear-gradient(90deg, #423f9c 31%, #862359 69%);
}
.center-brand.fixedmenu,
.bg-white.fixedmenu,
.bg-transparent-light.fixedmenu,
.bg-transparent-white.fixedmenu,
.bg-invisible.fixedmenu {
  background: #fff;
}
.fixed-bottom.fixedmenu {
  bottom: auto;
  -webkit-animation: none;
  animation: none;
}
.bg-invisible.fixedmenu .container {
  opacity: 1;
  visibility: visible;
}
@-webkit-keyframes animationFade {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes animationFade {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.nav-whitebg {
  background: #fff;
  transition: all 0.9s ease;
}
.navbar-brand {
  margin: 12px 0 22px 0;
  padding: 0;
  max-width: 120px;
  position: relative;
}

.navbar-brand-partner {
  margin: 10px 0 22px 0px;
  width: 140px;
  position: relative;
}

.navbar-brand-cuidando {
  margin: 10px 0 22px 0;
  max-width: 300px;
  position: relative;
}

.brand-cuidando {
  width: 100%;
}

.center-brand .navbar-brand {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
}
.navbar-brand > img {
  width: 100%;
}
.navbar.fixedmenu .logo-default,
.navbar .logo-scrolled {
  display: none;
}
.navbar.fixedmenu .logo-scrolled,
.navbar .logo-default {
  display: inline-block;
}

.navbar-nav .nav-item {
  margin: 16px 5px;
  position: relative;
}
.navbar-nav .nav-item:first-child {
  margin-left: 0;
}
.navbar-nav .nav-item:last-child {
  margin-right: 0;
}
.navbar-nav .nav-link {
  padding: 12px 1rem !important;
  font-weight: 400;
  font-size: 15px;
  color: #313341;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}
.center-brand .navbar-nav .nav-link,
.transparent-bg .navbar-nav .nav-link,
.fixed-bottom .navbar-nav .nav-link {
  color: #fff;
}
.center-brand.fixedmenu .navbar-nav .nav-link {
  color: #212331;
}
.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
  color: #00bcd4;
}
.fixedmenu .navbar-nav .nav-link.active,
.fixedmenu .navbar-nav .nav-link.active:hover,
.fixedmenu .navbar-nav .nav-link.active:focus,
.center-brand.fixedmenu .navbar-nav .nav-link.active {
  background: #00bcd4;
  color: #fff;
}
.center-brand .navbar-nav .nav-link:first-of-type {
  background: rgba(0, 0, 0, 0);
}
.color_pride {
  background: -webkit-linear-gradient(300deg, #3cb2df, #d3455b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*toggle responsive*/
.navbar-toggler {
  position: relative;
  border: none;
  border-radius: 0;
}
.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}
.navbar-toggler span {
  display: block;
  background: #fff;
  height: 2px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  transform: rotate(0deg);
  position: relative;
  left: 0;
  opacity: 1;
}
.bg-white .navbar-toggler span,
.bg-transparent-light .navbar-toggler span,
.bg-invisible .navbar-toggler span {
  background: #212331;
}
.center-brand.fixedmenu .navbar-toggler span {
  background: linear-gradient(90deg, #643094, #00bcd4);
}
.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out;
}
.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  transform: rotate(135deg);
  opacity: 0.9;
}
.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 3px;
  visibility: hidden;
  background-color: transparent;
}
.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  transform: rotate(-135deg);
  opacity: 0.9;
}

li.tag_psico {
  padding: 2px 10px;
  border-radius: 30px;
  overflow: hidden;
  background-image: linear-gradient(
    0deg,
    #e70000,
    #ff8c00,
    #ffef00,
    #00811f,
    #0044ff,
    #760089
  );
  color: #ffffff !important;
  font-weight: 600 !important;
}

/*-----Side Menu----*/
.side-menu {
  width: 40%;
  position: fixed;
  right: 0;
  top: 0;
  background: #00bcd4;
  z-index: 1032;
  height: 100%;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.5s ease;
  overflow: hidden;
}
.just-sidemenu .side-menu {
  background: linear-gradient(90deg, #423f9c, #862359);
}
.transparent-sidemenu .side-menu {
  background: rgba(0, 0, 0, 0.95);
}
.side-menu.side-menu-active {
  transform: translate3d(0, 0, 0);
}
.pul-menu .side-menu.side-menu-active {
  visibility: visible;
  opacity: 1;
}
.side-menu .navbar-brand {
  margin: 0 0 2.5rem 0;
}

/*Side overlay*/
#close_side_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  display: none;
  z-index: 1031;
  opacity: 0.4;
}

/*side clode btn*/
.side-menu .btn-close {
  height: 33px;
  width: 33px;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 30px;
  right: 15px;
  cursor: pointer;
}
.just-sidemenu #sidemenu_toggle {
  position: fixed;
  z-index: 999;
  /*background: #fff;*/
  right: 0;
  top: 16px;
  border-radius: 2px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.side-menu .btn-close::before,
.side-menu .btn-close::after {
  position: absolute;
  left: 16px;
  content: " ";
  height: 24px;
  width: 2px;
  background: #fff;
  top: 5px;
}
.side-menu .btn-close:before {
  transform: rotate(45deg);
}
.side-menu .btn-close:after {
  transform: rotate(-45deg);
}

/*side open btn*/
.sidemenu_btn {
  transition: all 0.3s linear;
  width: 36px;
  padding: 6px;
  margin-right: 1rem;
  position: absolute;
  right: 15px;
}
.sidemenu_btn > span {
  height: 2px;
  width: 100%;
  background: #212331;
  display: block;
}
.center-brand .sidemenu_btn > span,
.transparent-bg .sidemenu_btn > span,
.fixed-bottom .sidemenu_btn > span,
.just-sidemenu .toggle_white.sidemenu_btn > span,
.bg-invisible .sidemenu_btn > span {
  background: #fff;
}
.center-brand.fixedmenu .sidemenu_btn > span,
.bg-invisible.fixedmenu .sidemenu_btn > span {
  background: #212331;
}
.sidemenu_btn > span:nth-child(2) {
  margin: 4px 0;
}

.side-menu .inner-wrapper {
  padding: 3.5rem 5rem;
  height: 100%;
  position: relative;
  overflow-y: auto;
  display: flex;
  align-items: center;
  text-align: left;
  flex-wrap: wrap;
}
.pul-menu.pushwrap .side-menu .inner-wrapper {
  padding: 3.5rem 2.5rem;
}
.side-menu .side-nav {
  margin-bottom: 30px;
  display: block;
}
.side-nav .navbar-nav .nav-item {
  display: block;
  margin: 10px 0;
  padding: 0 !important;
  opacity: 0;
  transition: all 0.8s ease 500ms;
  transform: translateY(30px);
}
.side-nav .navbar-nav .nav-item:first-child {
  transition-delay: 0.1s;
}
.side-nav .navbar-nav .nav-item:nth-child(2) {
  transition-delay: 0.2s;
}
.side-nav .navbar-nav .nav-item:nth-child(3) {
  transition-delay: 0.3s;
}
.side-nav .navbar-nav .nav-item:nth-child(4) {
  transition-delay: 0.4s;
}
.side-nav .navbar-nav .nav-item:nth-child(5) {
  transition-delay: 0.5s;
}
.side-nav .navbar-nav .nav-item:nth-child(6) {
  transition-delay: 0.6s;
}
.side-nav .navbar-nav .nav-item:nth-child(7) {
  transition-delay: 0.7s;
}
.side-nav .navbar-nav .nav-item:nth-child(8) {
  transition-delay: 0.8s;
}
.side-nav .navbar-nav .nav-item:nth-child(9) {
  transition-delay: 0.9s;
}
.side-menu.side-menu-active .side-nav .navbar-nav .nav-item {
  transform: translateY(0);
  opacity: 1;
}
.side-nav .navbar-nav .nav-link {
  display: inline-table;
  color: #fff;
  padding: 2px 0 3px 0 !important;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: normal;
  position: relative;
  border-radius: 0;
}
.side-nav .navbar-nav .nav-link::after {
  content: "";
  position: absolute;
  background: #fff;
  display: inline-block;
  width: 0;
  height: 3px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
}
.transparent-sidemenu .side-nav .navbar-nav .nav-link::after {
  display: none;
}
.side-nav .navbar-nav .nav-link:hover::after,
.side-nav .navbar-nav .nav-link:focus::after {
  width: 100%;
}
.side-nav .navbar-nav .nav-link.active {
  background: transparent;
}
.transparent-sidemenu .side-nav .navbar-nav .nav-link:hover,
.transparent-sidemenu .side-nav .navbar-nav .nav-link:focus {
  margin-left: 5px;
}
.side-menu p {
  font-size: 13px;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

/*Header Social Icons*/
.navbar .social-icons li a {
  height: 28px;
  width: 28px;
  line-height: 28px;
  font-size: 14px;
}

@media (max-width: 1366px) {
  .center-brand .sidemenu_btn {
    right: 0;
  }
}
@media (max-width: 1200px) {
  .side-menu .inner-wrapper {
    padding: 2rem 3.5rem;
  }
}
@media (min-width: 1025px) and (max-width: 1091px) {
  .navbar-nav.ml-auto {
    margin-right: 30px;
  }
  .sidemenu_btn {
    right: 0;
  }
}
@media (max-width: 1024px) {
  .sidemenu_btn {
    position: relative;
  }
  .center-brand .navbar-brand {
    width: 100px;
  }
  .navbar-nav .nav-link {
    font-size: 13px;
  }
}
@media (max-width: 992px) {
  .center-brand .navbar-brand {
    position: relative;
    top: auto;
    margin: 16px 0;
    transform: translateY(0);
  }
  .navbar-nav .nav-item {
    margin: 5px 0;
  }
  .center-brand .navbar-nav .nav-link,
  .transparent-bg .navbar-nav .nav-link {
    background: #00bcd4;
    color: #fff;
  }
  .center-brand.fixedmenu .navbar-nav .nav-link,
  .transparent-bg.fixedmenu .navbar-nav .nav-link {
    background: transparent;
  }

  .side-menu {
    width: 50%;
  }
  .side-menu .inner-wrapper {
    padding: 2rem 2.5rem;
  }
  .side-nav .navbar-nav .nav-link {
    font-size: 2rem;
  }
}
@media (max-width: 768px) {
  .center-brand .navbar-brand {
    width: 100px;
  }
  .side-menu {
    width: 55%;
  }
  .side-nav .navbar-nav .nav-link {
    font-size: 1.5rem;
  }
}

@media (max-width: 767px) {
  .sidemenu_btn {
    margin-top: 4px;
    right: 0;
    position: absolute;
  }
  .side-menu {
    width: 300px;
  }
  .side-nav .navbar-nav .nav-item {
    margin: 5px 0;
  }
  .side-nav p {
    display: none;
  }

  .padding_bottom_acompanhamento {
    padding-bottom: 0rem;
  }
}

/*-------------------------------*/
/*Navigation Ends */
/*-------------------------------*/

/*-------------------------------*/
/* Main Banner Ends*/
/*-------------------------------*/

/*-------------------------------*/
/* Evento*/
/*-------------------------------*/

.eventtextcontainer {
  padding-right: 40px;
}

.eventlateralbar {
  padding-bottom: ;
}

.eventcontainer h4 {
  font-size: 20px;
  color: #212331;
  font-weight: 900;
}

.eventcontainer p {
  font-size: 16px;
  color: #212331;
  margin-bottom: 0;
  font-weight: 300;
}

.headingofevent {
  border-bottom: 1px solid #e6e6e6;
  padding: 40px 0;
}

.typeofevent p {
  margin: 0;
  padding-left: 5px;
  color: #212331;
  text-transform: uppercase;
  font-weight: 900 !important;
}

.typeofevent i {
  font-size: 16px;
  color: #212331;
}

.titleofevent {
  padding-bottom: 10px;
  color: #212331;
  font-weight: 900;
  font-size: 40px;
}

.subtitleofevent {
  padding-bottom: 20px;
  font-weight: 300;
}

.tagsofevent {
  font-weight: 600 !important;
}

.detailsofevent {
  border-bottom: 1px solid #e6e6e6;
  padding: 40px 0;
  width: 100%;
}

.lineofdetailcontainers {
  width: 100%;
}

.detailcontainer {
  margin: 0;
  width: 48%;
}

.detailcontainer i {
  font-size: 36px;
  color: #212331;
}

.detailcontainer p {
  margin-left: 15px;
}

.abouttheevent {
  padding: 40px 0;
  width: 100%;
}

.abouttheevent h4 {
  margin-bottom: 20px;
}

.image-event {
  padding: 40px 0 0 0;
  width: 100%;
}

.image-event .img {
  border-style: solid;
  border-width: 1px;
  border-color: #aaaaaa;
  border-radius: 12px;
  width: 220px;
  height: 360px;
  object-fit: cover;
  width: 100%;
}

.application-box {
  margin-top: 40px;
  padding: 20px 15px;
  border: 1px solid #c6c6c6;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.application-box h4 {
  padding-left: 10px;
}

.application-box i {
  font-size: 20px;
  color: #e51d49;
}

.event-credits {
  padding-bottom: 15px;
  border-bottom: 1px solid #e6e6e6;
}

.available-credits {
  padding-top: 5px;
}

.apply-to-event {
  padding: 20px 0 5px 0;
}

.application-text {
  width: 60%;
}

.event-application-button {
  width: 36%;
  background: #e51d49;
  border-radius: 5px;
  color: white;
  height: 36px;
  font-weight: 500;
}

.event-share-button {
  width: 36%;
  background: #eee;
  border-radius: 5px;
  color: #212331;
  height: 36px;
  font-weight: 500;
}

/*-------------------------------*/
/* Evento Ends*/
/*-------------------------------*/

/*-------------------------------*/
/* Pesquisa Psicólogo*/
/*-------------------------------*/

.search-psi {
  padding: 0 !important;
  border: 1px solid #c6c6c6;
  border-radius: 12px;
}

.search-psi input {
  border: 1px solid #ffffff;
  height: 56px;
  padding-left: 10px;
  font-color: #313341;
  font-weight: 500;
  font-size: 17px;
  border-radius: 12px 0 0 12px;
}

.first-input {
  border-right-color: #aaa !important;
  width: 45%;
}

.second-input {
  border-right-color: #aaa !important;
  border-radius: 0 0 0 0;
  width: 20%;
}

.third-input {
  border-radius: 0 0 0 0;
  width: 20%;
}

.input-button {
  width: 15%;
  background: #e51d49;
  border-radius: 0 11px 11px 0;
  color: white;
}

/* Form general- */
::-webkit-input-placeholder,
.search-psi input::-webkit-input-placeholder {
  color: #666666;
  font-weight: 300;
}
:-moz-placeholder,
.search-psi input:-moz-placeholder {
  /* Firefox 18- */
  color: #666666;
  font-weight: 300;
}
::-moz-placeholder,
.search-psi input::-moz-placeholder {
  /* Firefox 19+ */
  color: #666666;
  font-weight: 300;
}
:-ms-input-placeholder,
.search-psi input:-ms-input-placeholder {
  color: #666666;
  font-weight: 300;
}
textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder {
  color: #666666;
  font-weight: 300;
  opacity: 1;
}

.counter-container {
  margin: auto;
}

.counter-psico {
}

.counter-psico p {
  color: #313341;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
}

.dates-section {
  background: #fff;
  padding: 0;
  align-items: center;
  z-index: 9;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #c6c6c6;
}

.fixed {
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  background: #e51d49;
  border-bottom: 1px solid #d01d46;
}

.fixed p {
  color: white !important;
}

.fixed h5 {
  color: white !important;
}

.fixed i {
  color: white !important;
}

.dates-section + .fixed {
  padding-top: 60px;
}

.dates-on-view {
  width: 100%;
}

.given-date {
  padding: 10px 0;
  width: 23%;
  text-align: center;
}

.given-date p {
  font-size: 10px;
  font-weight: 600;
  color: #313341;
  padding: 2px 0;
  margin: 0;
}

.given-date h5 {
  font-size: 15px;
  font-weight: 600;
  color: #313341;
  padding: 2px 0;
  margin: 0;
}

.dates-icon-left {
  margin: auto 0 auto 10px;
}

.dates-icon-right {
  margin: auto 10px auto 0;
}

.dates-icon-left i {
  font-size: 32px !important;
  color: #313341;
}

.dates-icon-right i {
  font-size: 32px !important;
  color: #313341;
}

/*.psico-pic {
  padding: 0;
  display: block;
}*/

.psico-pic {
  border-style: solid;
  border-width: 1px;
  border-color: #aaaaaa;
  border-radius: 12px;
  width: 100%;
  height: auto;
  margin: auto;
  padding: 0 !important;
}

.psico-pic img {
  display: ;
}

.nome-psico {
  font-size: 24px !important;
  font-weight: 900 !important;
}

.referencia-psico {
  margin: auto 0 !important;
}

.prof-psico {
  font-size: 14px !important;
  text-transform: uppercase;
  font-weight: 900 !important;
  color: #e51d49;
  padding-right: 8px;
}

.nota-psico {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #313341;
  padding-left: 4px;
}

.nota-estrela {
  display: block;
}

.nota-estrela img {
  height: 14px !important;
  width: 14px !important;
}

.domini-psico,
.about-psico {
  font-size: 14px !important;
  font-weight: 300 !important;
  color: #313341;
}

.calendar-container {
  padding: 10px 32px 10px 47px;
}

.calendar {
  width: 100%;
}

.calendar-column {
  width: 23%;
}

.calendar-cell {
  margin-bottom: 8px;
}

.calendar-cell a {
  width: 100%;
  padding: 6px 0;
  background: #eee;
  text-align: center;
  color: #313341;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  display: block;
}

.calendar-cell a:hover {
  background: #e51d49;
  color: #fff;
  text-decoration: underline;
}

/*-------------------------------*/
/* Pesquisa Psicólogo Ends*/
/*-------------------------------*/

/*-------------------------------*/
/*Page Headers */
/*-------------------------------*/
.page-header {
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
}
.page-header .page-titles {
  position: relative;
  margin-top: 5rem;
  text-transform: capitalize;
}
.breadcrumb {
  background: transparent;
  padding: 0;
}
.breadcrumb .breadcrumb-item {
  color: #fff;
  font-size: 14px;
}
.breadcrumb .breadcrumb-item:hover,
.breadcrumb .breadcrumb-item:focus,
.breadcrumb .breadcrumb-item.active {
  color: #00bcd4;
}
#particles canvas {
  position: absolute;
}
.text-center-left {
  text-align: left !important;
}
.text-center-right {
  text-align: right !important;
}

/*-------------------------------*/
/*Page Headers */
/*-------------------------------*/

/*-------------------------------*/
/* Work Process */
/*-------------------------------*/
.process-wrapp {
  overflow: hidden;
  width: 100%;
  display: table;
  table-layout: fixed;
  margin: 0 15px;
}
.process-wrapp,
.process-wrapp li,
.process-wrapp li > .pro-step {
  position: relative;
  z-index: 1;
}
.process-wrapp li {
  text-align: center;
  padding: 20px 15px 0 15px;
  display: table-cell;
}
.process-wrapp li:hover {
  cursor: pointer;
}
.process-wrapp li > .pro-step {
  display: inline-block;
  height: 90px;
  width: 90px;
  line-height: 90px;
  border: 1px solid #fff;
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}
.process-wrapp li p:first-child {
  font-size: 1.5rem;
}
.process-wrapp li p:last-child {
  font-size: 14px;
}
.process-wrapp li:hover > .pro-step {
  box-shadow: 0 0 25px 50px #fff inset;
  color: #00bcd4;
}
/*.process-wrapp li::before, .process-wrapp li::after {
    content: "";
    height: 1px;
    top: 115px;
    background: rgba(255,255,255, .5);
    position: absolute;
    width: 50%;
    z-index: -1;
    margin: 0 -45px;
}*/
.process-wrapp li:first-child::before,
.process-wrapp li:last-child::after {
  opacity: 0;
  visibility: hidden;
}
.process-wrapp li::before {
  left: 0;
}
.process-wrapp li::after {
  right: 0;
}

/*process with box */
.process-number {
  background: #fff;
  padding: 0 1rem 2.5rem 1rem;
  position: relative;
  text-align: center;
  border-radius: 5px;
}
.process-number,
.process-number .pro-step {
  transition: all 0.5s linear;
}
.process-number:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.process-number .pro-step {
  font-weight: bold;
  font-size: 1.75rem;
  font-family: "Open Sans", sans-serif;
  margin-right: 5px;
  color: #fff;
  line-height: 1;
  background: #00bcd4;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  display: inline-block;
  margin-top: -50px;
}
.process-number .pro-step::after {
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: inline-block;
  background: #fff;
  margin-left: 3px;
}
.process-number.even .pro-step {
  background: #643094;
}

@media screen and (max-width: 992px) {
  .process-wrapp {
    text-align: center;
  }
  .process-wrapp li {
    display: inline-block;
    max-width: 300px;
  }
  .process-wrapp li:first-child::before,
  .process-wrapp li:last-child::after {
    opacity: 1;
    visibility: visible;
  }
}

/*-------------------------------*/
/*Planos */
/*-------------------------------*/

.team-content {
  padding: 10px 20px;
  color: #313341;
}

.team-content h4 {
  font-size: 1.8rem;
  font-weight: 500;
}

.team-content .overwrite-row p {
  margin: 0 5px 0 0;
}

.team-content p {
  font-size: 17px;
  margin: 4px 0 0 0;
}

.team-content img {
  height: 100%;
}

.psico-content {
  padding: 10px 0px;
  color: #313341;
}

.team-box {
  margin: 20px 0;
  padding: 20px 0;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 12px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
}

.team-box-plano {
  margin: 6px 6px 24px 6px;
  background-color: white;
  border-radius: 5px;
}

.plano-space {
  padding: 0;
}

.consulta-box {
  margin: 0px 20px 20px 20px;
  padding: 10px 0;
  background-color: white;
  border-radius: 5px;
  min-height: 12rem;
  align-content: start;
}

.plano-box {
  margin: 0px 20px 20px 20px;
  padding: 10px 0;
  background-color: white;
  border-radius: 5px;
  min-height: 22rem;
  align-content: start;
}

.plano-box:hover {
  box-shadow: 0 0 10px #c6c6c6;
}

.plano-box-content {
  padding: 10px 20px;
  color: #313341;
}

.plano-box-price {
  padding: 10px 20px;
  color: #313341;
  text-align: right;
}

.plano-box-content h4 {
  font-size: 1.8rem;
  font-weight: 500;
}

.plano-box-content .overwrite-row p {
  margin: 0 5px 0 0;
}

.plano-box-content p {
  font-size: 17px;
  margin: 0 0 8px 0;
}

.plano-box-content img {
  height: 100%;
}

.best-box {
  background-color: #3cb2df;
}

.best-box p {
  color: white;
}

.best-box h5 {
  color: white;
}

.best-box span {
  color: black;
}

.pay-content p {
  font-size: 15px;
}

#show-boleto {
  margin-top: 10px !important;
  font-size: 19px;
}

.collapsible {
  background-color: #eeeeee;
  color: #313341;
  cursor: pointer;
  padding: 18px;
  margin: 6px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 1.25rem;
  font-weight: 400;
  border-radius: 5px;
}

.collapsible-faq {
  background-color: #eeeeee;
  color: #313341;
  cursor: pointer;
  padding: 18px;
  margin: 6px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 1.25rem;
  font-weight: 400;
  border-radius: 5px;
}

.collapsible-comofunciona {
  background-color: #f7f9ff;
  color: #313341;
  cursor: pointer;
  padding: 18px;
  margin: 6px 0;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 1.25rem;
  font-weight: 400;
  border-radius: 5px;
}

.active-basic,
.collapsible-basic:hover {
  background: rgb(60, 178, 223);
  background: linear-gradient(
    90deg,
    rgba(229, 29, 73, 1) 0%,
    rgba(210, 46, 90, 1) 26%,
    rgba(58, 147, 217, 1) 74%,
    rgba(46, 155, 226, 1) 100%
  );
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  color: white;
  outline: none;
}

.active-faq,
.collapsible-faq:hover {
  background: rgb(60, 178, 223);
  background: linear-gradient(
    90deg,
    rgba(229, 29, 73, 1) 0%,
    rgba(210, 46, 90, 1) 26%,
    rgba(58, 147, 217, 1) 74%,
    rgba(46, 155, 226, 1) 100%
  );
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  color: white;
  outline: none;
}

.active-comofunciona,
.collapsible-comofunciona:hover {
  background: #2e9be2;
  /*background: linear-gradient(90deg, rgba(229,29,73,1) 0%, rgba(210,46,90,1) 26%, rgba(58,147,217,1) 74%, rgba(46,155,226,1) 100%);*/
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  color: white;
  outline: none;
}

.colorful {
  background: rgb(60, 178, 223) !important;
  background: linear-gradient(
    90deg,
    rgba(229, 29, 73, 1) 0%,
    rgba(210, 46, 90, 1) 26%,
    rgba(58, 147, 217, 1) 74%,
    rgba(46, 155, 226, 1) 100%
  ) !important;
}

.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #ffffff;
  border-left-width: 2px;
  border-left-color: #313341;
  border-left-style: solid;
  text-align: left;
  color: #313341;
}

.content p {
  font-size: 1rem;
  font-weight: 200;
}

@media (max-width: 450px) {
  .collapsible {
    padding: 18px;
    margin: 6px 0;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 1.4rem;
    font-weight: 400;
    border-radius: 5px;
  }

  .active,
  .collapsible:hover {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    color: white;
  }

  .content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #ffffff;
  }

  .content p {
    font-size: 1.25rem;
    font-weight: 200;
  }

  .padding_main_banner {
    padding: 8rem 0 4.5rem 0;
  }

  .team-content {
    padding: 10px 10px 0 10px;
    color: #313341;
  }

  .team-content-plano {
    padding: 10px 10px 10px 10px;
  }

  .team-box {
    padding: 0 0 6px 0;
    margin: 30px 0 40px 0;
    background-color: #ffffff;
    border-radius: 5px;
  }

  .plano-space {
    padding: 0 15px;
  }

  .consulta-box {
    margin: 0 0 20px 0;
    padding: 5px 0;
    background-color: white;
    border-radius: 5px;
    min-height: 5rem;
  }

  .plano-box {
    padding: 5px 0;
    margin: 0 0 20px 0;
    background-color: #ffffff;
    border-radius: 5px;
    min-height: 5rem;
  }

  .plano-box span {
    font-size: 17px;
  }

  .team-box-plano {
    margin: 0px 0px 6px 0px;
    margin: 10px 0 20px 0;
    background-color: white;
    border-radius: 5px;
  }

  .team-content .overwrite-row p {
    margin: 0 5px 0 0;
  }

  .team-content p {
    font-size: 17px;
    margin: 10px 0 0 0;
  }

  .plano-box-content {
    padding: 10px 10px 10px 10px;
    color: #313341;
  }

  .plano-box-items {
    padding: 0px 10px 10px 10px;
  }

  .plano-box-content .overwrite-row p {
    margin: 0 5px 0 0;
  }

  .plano-box-content p {
    font-size: 17px;
  }

  .best-box {
    background-color: #e2405c;
  }

  .best-box p {
    color: white;
  }

  .best-box h5 {
    color: white;
  }

  .best-box span {
    color: black;
  }

  .pay-content p {
    font-size: 15px;
    margin: 4px 0 0 0;
  }
}

/*-------------------------------*/
/* Mobile Apps & Features */
/*-------------------------------*/

/*single feature*/
.single-feature,
.bg-apps {
  position: relative;
}
/*apps background*/
.bg-apps {
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
}
.bg-apps .image {
}

/*app features*/
.feature-item .icon {
  transition: all 0.5s ease-in;
}
.opacities {
  opacity: 0.5;
}
#app-feature .feature-item {
  padding: 15px 0;
}
#app-feature .feature-item:hover {
  cursor: pointer;
}
.feature-item img,
.feature-item .icon {
  display: inline-block;
}
.feature-item img {
  max-width: 290px;
}
.feature-item h4 {
  font-weight: 600;
  color: #222222;
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.feature-item .icon {
  background: #2e9be2;
  width: 90px;
  height: 90px;
  text-align: center;
  position: relative;
  border-radius: 50%;
  z-index: 1;
  font-size: 34px;
  line-height: 90px;
  color: #fff;
}
.feature-item .icon::before {
  content: "";
  background: transparent;
  top: 1px;
  left: 1px;
  bottom: 1px;
  right: 1px;
  position: absolute;
  border-radius: 50%;
  border: 4px solid #fff;
}
.feature-item:hover .icon {
  -webkit-animation: itg_pulse 1s infinite;
  animation: itg_pulse 1s infinite;
  box-shadow: 0 0 0 0 rgba(100, 48, 148, 1);
}

@-webkit-keyframes itg_pulse {
  0% {
    box-shadow: 0 0 0 5px rgba(100, 48, 148, 0.9);
  }
  25% {
    box-shadow: 0 0 0 10px rgba(100, 48, 148, 0.6);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(100, 48, 148, 0.3);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(100, 48, 148, 0.1);
  }
}
@keyframes itg_pulse {
  0% {
    box-shadow: 0 0 0 5px rgba(100, 48, 148, 0.9);
  }
  25% {
    box-shadow: 0 0 0 10px rgba(100, 48, 148, 0.6);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(100, 48, 148, 0.3);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(100, 48, 148, 0.1);
  }
}

#app-feature .feature-item:hover .icon {
  background: #643094;
}
#app-feature .content-left {
  text-align: right;
}
#app-feature .content-right {
  text-align: left;
}
#app-feature .content-left span {
  float: right;
  margin-left: 15px;
}
#app-feature .content-right span {
  float: left;
  margin-right: 15px;
}
#app-feature .content-left .text {
  margin-right: 105px;
}
#app-feature .content-right .text {
  margin-left: 105px;
}

.feature-item.active {
  background: #f7f7f7;
}
.image.mobile-apps > img {
  opacity: 0;
}
.image.mobile-apps.active > img {
  opacity: 1;
}

@media screen and (max-width: 992px) {
  #app-feature,
  #app-feature .content-left,
  #app-feature .content-right {
    text-align: center;
  }
  #app-feature .feature-item {
    padding: 0;
    max-width: 300px;
    display: inline-block;
    float: none;
  }
  #app-feature .content-left span,
  #app-feature .content-right span {
    float: none;
    margin: 0 auto 15px auto;
    display: inline-block;
  }

  #app-feature .content-left .text,
  #app-feature .content-right .text {
    margin: 0;
  }
}

/*-------------------------------*/
/*Mobile Apps ends */
/*-------------------------------*/

/*-------------------------------*/
/* Counters */
/*-------------------------------*/
.fact-iconic,
.counter-photo {
  position: relative;
}
.counter-photo ::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 17px;
  background: #00bcd4;
  top: 10px;
  left: 10px;
  bottom: 0;
  display: inline-block;
  z-index: 1;
}
.counter-photo img {
  z-index: 2;
  padding: 0 15px 15px 0;
}
.fact-iconic h3,
.counters .count_nums {
  font-weight: bold;
}
.fact-iconic h3::after {
  content: "";
  height: 3px;
  width: 50px;
  background: #ffffff;
  display: block;
  margin-top: 15px;
}
.text-center .fact-iconic h3::after,
.text-center.fact-iconic h3::after {
  margin-left: auto;
  margin-right: auto;
}
.icon-counters.table-display {
  display: table;
}
.icon-counters .img-icon {
  text-align: center;
  font-size: 40px;
  height: 70px;
  width: 70px;
  line-height: 70px;
  border-radius: 50%;
  transition: all 0.7s ease-in 0.3s;
  transform-style: preserve-3d;
}
.bg-counter-light .icon-counters .img-icon {
  height: 100px;
  width: 100px;
  line-height: 100px;
  transition: all 0.3s ease;
}
.icon-counters.table-display .img-icon {
  display: table-cell;
  vertical-align: middle;
  font-size: 50px;
  width: 90px;
}
.icon-counters.table-display .img-icon,
.icon-counters.table-display:hover .img-icon,
.bg-counter-light .icon-counters .img-icon,
.bg-counter-light .icon-counters:hover .img-icon {
  transform: none;
}
.icon-counters:hover .img-icon {
  transform: rotateY(180deg);
}
.bg-counter-light .icon-counters:hover .img-icon {
  color: #fff;
  background: #00bcd4;
}
.icon-counters .img-icon,
.counters .count_nums,
.counters i {
  display: inline-block;
}
.counters .count_nums {
  font-size: 2rem;
}
.icon-counters.table-display .count_nums {
  font-size: 2.5rem;
}
.counters .count_nums,
.counters i {
  vertical-align: middle;
}
.bg-counter-light {
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .fact-iconic h3::after {
    margin-left: auto;
    margin-right: auto;
  }
}
/*-------------------------------*/
/*Counters ends */
/*-------------------------------*/

/*-------------------------------*/
/* Pricings */
/*-------------------------------*/
.price-table,
.price-table * {
  transition: all 0.4s ease-in;
}
.pricing-bg {
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  width: 100%;
}
.pricing-bg::before {
  content: "";
  height: 50%;
  width: 100%;
  bottom: 0;
  position: absolute;
  background: #f6f6f6;
}
.price-table {
  padding: 4.375rem 5.5rem;
  border: 1px solid #f6f3f3;
  background: #fff;
  border-radius: 10px;
}
.three-col-pricing .price-table,
.centered-table .price-table {
  padding: 3rem 1.5rem;
}
.three-col-pricing .price-table,
.centered-table .price-table,
.three-col-pricing .price-table ul,
.centered-table .price-table ul {
  text-align: center;
}
.price-table h3 {
  font-size: 1.875rem;
}
.price-table h3,
.price-table .ammount h2 {
  font-weight: normal;
}
.centered-table .price-table h3,
.centered-table .price-table .ammount h2 {
  font-weight: bold;
}
.price-table .ammount *,
.price-table ul,
.price-table ul li > span {
  display: inline-block;
}
.price-table .ammount * {
  vertical-align: middle;
}
.price-table .ammount .dur {
  font-size: 1rem;
  color: #545661;
  margin-top: 10px;
}
.price-table .ammount i {
  margin-top: -5px;
  font-size: 80%;
  margin-right: -5px;
}
.price-table .ammount h2 {
  line-height: 1;
  font-size: 3.125rem;
}
.price-table.active .ammount h2 {
  color: #643094;
}
.price-table ul {
  text-align: left;
}

.price-table ul li {
  margin-top: 1.25rem;
}
.price-table ul li {
  color: #6e6e6e;
  font-size: 14px;
}
.price-table ul li.not-support {
  color: #9b9b9b;
}
.price-table ul li.not-support > span {
  text-decoration: line-through;
}
.price-table ul li > span {
  padding-left: 20px;
}
.price-table ul li > span::before {
  content: "\f00c";
  color: #30e512;
  margin-right: 8px;
  width: 20px;
  margin-left: -20px;
}
.price-table ul li.not-support > span::before {
  content: "\f00d";
  color: #ff434b;
}
.centered-table .price-table ul li > span::before {
  display: none;
}
.centered-table .price-table ul li > span::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #f6f6f6;
  margin: 0 auto;
  display: inline-block;
}
.centered-table .price-table ul li:last-child > span::after {
  width: 0;
}

.price-table:hover {
  background: #00bcd4;
  border-color: #00bcd4;
}
.price-table.active:hover {
  background: #643094;
  border-color: #643094;
}
.price-table:hover *,
.price-table:hover .ammount *,
.price-table:hover .ammount h2,
.price-table:hover .ammount .dur,
.price-table:hover ul li > span::before,
.price-table:hover .btnsecondary,
.price-table:focus .btnsecondary,
.price-table:hover .btnprimary,
.price-table:focus .btnprimary {
  color: #fff;
}
.price-table:hover .btnsecondary,
.price-table:focus .btnsecondary,
.price-table:hover .btnprimary,
.price-table:focus .btnprimary {
  border-color: #fff;
  background: transparent;
}
.price-table .btnsecondary:hover,
.price-table .btnsecondary:focus,
.price-table .btnprimary:hover,
.price-table .btnprimary:focus {
  border-color: #fff;
  background: #fff;
  color: #212331;
}
.price-table:hover {
  box-shadow: 0 0 5px rgba(101, 101, 101, 0.2);
}

@media (max-width: 992px) {
  .price-table {
    padding: 3.375rem 4rem;
  }
}
@media (max-width: 768px) {
  .price-table {
    padding: 2.375rem 2rem;
  }
}
@media (max-width: 768px) {
  .price-table {
    padding: 2.375rem 2rem;
  }
}

/*-------------------------------*/
/* Pricings ends */
/*-------------------------------*/

/*-------------------------------*/
/* Testimonials*/
/*-------------------------------*/
.testimonial-wrapp,
.testimonial-wrapp .testimonial-text,
.testimonial-wrapp .testimonial-photo,
.testimonial-wrapp .quoted,
.testimonial-quote {
  position: relative;
  transition: all 0.4s ease-in;
}
.testimonial-wrapp,
.testimonial-wrapp .testimonial-text {
  text-align: center;
}
.testimonial-wrapp .quoted,
.testimonial-wrapp .testimonial-photo {
  display: inline-block;
}
.testimonial-wrapp .quoted {
  background: #643094;
  color: #fff;
  font-size: 20px;
  line-height: 50px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transform: translateY(50%);
  z-index: 1;
}
.testimonial-wrapp .testimonial-text {
  background: #f6f6f6;
  padding: 4rem 1.5rem;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
}
.testimonial-wrapp .testimonial-photo {
  height: 100px;
  width: 100px;
  margin-top: -50px;
}
.testimonial-wrapp .testimonial-photo,
.testimonial-wrapp .testimonial-photo > img {
  border-radius: 50%;
}
#testimonial-slider .owl-item:nth-child(2n) .testimonial-wrapp .quoted,
#testimonial-slider
  .owl-item:nth-child(2n)
  .testimonial-wrapp:hover
  .testimonial-text {
  background: #00bcd4;
}
.testimonial-wrapp:hover .testimonial-text {
  background: #643094;
  color: #fff;
}

.testimonial-text h5 {
  color: #313343;
}

.testimonial-text:hover h5 {
  color: #ffffff;
}

/*Testimonial Quotes*/
.testimonial-bg {
}
.testimonial-bg-light {
}
.testimonial-bg,
.testimonial-bg-light {
  background-position: center center;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  width: 100%;
}

.testimonial-quote {
  text-align: left;
  padding-top: 10px;
}
.no-quote .testimonial-quote {
  text-align: center;
}
.testimonial-quote h3 {
  position: relative;
}
.no-quote .testimonial-quote h3::before,
.no-quote .testimonial-quote h3::after {
  display: none;
}
.testimonial-quote h3::before,
.testimonial-quote h3::after {
  display: inline-block;
  font-size: 17px;
}
.testimonial-quote h3::before {
  content: "\f10d";
  margin-right: 3px;
  transform: translateY(-10px);
}
.testimonial-quote h3::after {
  content: "\f10e";
  margin-left: 3px;
  transform: translateY(10px);
}
.testimonial-quote h6 {
  font-weight: 300;
}

#owl-thumbs.owl-dots {
  text-align: left;
}
#owl-thumbs.owl-dots .owl-dot {
  background: transparent;
  height: 60px;
  margin: 0;
  position: relative;
  width: 60px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  margin-left: -30px;
  opacity: 0.65;
  transform: scale(0.95);
}
.testimonial-bg-light #owl-thumbs.owl-dots .owl-dot {
  border: 5px solid rgba(0, 0, 0, 0.3);
}
#owl-thumbs.owl-dots .owl-dot > img {
  width: 100%;
  border-radius: 50%;
}
#owl-thumbs.owl-dots .owl-dot:first-child {
  margin-left: 0;
}
#owl-thumbs.owl-dots .owl-dot::after {
  display: none;
}
#owl-thumbs.owl-dots .owl-dot:hover,
#owl-thumbs.owl-dots .owl-dot.active {
  z-index: 5;
  opacity: 1;
  border: 5px solid rgba(255, 255, 255, 0.53);
  transform: scale(1);
}
.testimonial-bg-light #owl-thumbs.owl-dots .owl-dot.active {
  border: 5px solid rgba(0, 0, 0, 0.53);
}

/*-------------------------------*/
/* Testimonials ends*/
/*-------------------------------*/

/*-------------------------------*/
/* Partner/ Logo's*/
/*-------------------------------*/
#partners-slider .item {
  text-align: center;
}
#partners-slider .item,
#partners-slider .logo-item {
  position: relative;
}
#partners-slider .logo-item {
  width: 160px;
  height: 80px;
  overflow: hidden;
}
#partners-slider .logo-item,
#partners-slider .logo-item > img {
  display: inline-block;
}
.logo-item > img {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;

  object-fit: contain;
}

#midias-slider .item {
  text-align: center;
}
#midias-slider .item,
#midias-slider .logo-item {
  position: relative;
}
#midias-slider .logo-item {
  width: 160px;
  height: 80px;
  overflow: hidden;
}
#midias-slider .logo-item,
#midias-slider .logo-item > img {
  display: inline-block;
}

/*-------------------------------*/
/* Partner/ Logo's ends*/
/*-------------------------------*/

/*-------------------------------*/
/* Contact US*/
/*-------------------------------*/
#map-container {
  height: 450px;
  width: 100%;
  position: relative;
}
.our-address h5 {
  color: #222;
  font-weight: bold;
}
.our-address .pickus {
  font-size: 13px;
  text-transform: uppercase;
  color: #00bcd4;
  font-weight: 800;
  position: relative;
}
.our-address .pickus,
.our-address .pickus::after {
  display: inline-block;
}
.our-address .pickus::after {
  content: attr(data-text);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  color: #212331;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
}
.our-address .pickus:hover::after {
  width: 100%;
}
.our-address .pickus:hover,
.our-address .pickus:focus {
  color: #212331;
}

/*Contact Form*/
.getin_form .form-control {
  border: none;
  border-bottom: 1px solid #a5a5a5;
  padding: 12px 0;
  background: #fff;
  border-radius: 0;
  box-shadow: none;
  height: 44px;
  color: #a5a5a5;
  font-size: 14px;
  position: relative;
  transition: border 0.9s ease;
}
textarea {
  min-height: 175px;
  resize: none;
}
button {
  border: none;
  cursor: pointer;
}
.getin_form .form-control:focus {
  border-bottom: 1px solid #212331;
}
.getin_form .button {
  width: 100%;
}

/* Form general- */
::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #666666;
}
:-moz-placeholder,
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #666666;
}
::-moz-placeholder,
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #666666;
}
:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #666666;
}
textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder {
  color: #666666;
  opacity: 1;
}

/*-------------------------------*/
/*  COntact US */
/*-------------------------------*/

/*-------------------------------*/
/* Error */
/*-------------------------------*/
#error .error,
.error h1 {
  position: relative;
}
.error h1,
.error h2 {
  font-weight: bold;
}
.error h1 {
  color: #ebebeb;
  font-size: 10rem;
}
.error h2 {
  color: #414141;
  font-size: 3.2rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
#error p {
  margin-left: 10%;
  margin-right: 10%;
}

/*-------------------------------*/
/* Error ends */
/*-------------------------------*/

/*-------------------------------*/
/* Tabs & Accordions*/
/*-------------------------------*/
.accordion .card-header:after {
  font-family: "FontAwesome";
  content: "\f068";
  float: right;
}
.accordion .card-header.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\f067";
}
/*-------------------------------*/
/* Tabs & Accordions*/
/*-------------------------------*/

/*-------------------------------*/
/* Footer*/
/*-------------------------------*/
footer .copyrights {
  font-size: 13px;
}
.footer-logo {
  display: inline-block;
  max-width: 230px;
}
footer ul.social-icons:not(.small) li a {
  height: 60px;
  width: 60px;
  line-height: 60px;
  font-size: 26px;
  border: none;
  background: transparent;
}
footer ul.social-icons li a {
  background: #f5f5f5;
}
footer .copyrights > a:hover,
footer .copyrights > a:focus {
  color: #00bcd4;
}

/*-------------------------------*/
/* Footer ends*/
/*-------------------------------*/

/*-------------------------------*/
/*PreLoader*/
/*-------------------------------*/
/*.loader {
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1111;
  background:#fff;
  overflow-x:hidden;
}
.loader-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.loader-blocks {
   height: 58px;
   width: 58px;
}
.loader span {
   background: -webkit-linear-gradient(90deg, #423f9c 31%, #862359 69%);
   background: -webkit-gradient(linear, left top, right top, color-stop(31%, #423f9c), color-stop(69%, #862359));
   background: -webkit-linear-gradient(left, #423f9c 31%, #862359 69%);
   background: -o-linear-gradient(left, #423f9c 31%, #862359 69%);
   background: linear-gradient(90deg, #423f9c 31%, #862359 69%);
   -webkit-border-radius: 50%;
   border-radius: 50%;
   display: block;
   height: 12px;
   opacity: 0;
   position: absolute;
   width: 12px;
   animation: load 4.6s ease-in-out infinite;
   -o-animation: load 4.6s ease-in-out infinite;
   -ms-animation: load 4.6s ease-in-out infinite;
   -webkit-animation: load 4.6s ease-in-out infinite;
   -moz-animation: load 4.6s ease-in-out infinite;
}
.loader span.block-1 {
   animation-delay: 1.06s;
   -o-animation-delay: 1.06s;
   -ms-animation-delay: 1.06s;
   -webkit-animation-delay: 1.06s;
   -moz-animation-delay: 1.06s;
   left: 0px;
   top: 0px;
}
.loader span.block-2 {
   animation-delay: 0.97s;
   -o-animation-delay: 0.97s;
   -ms-animation-delay: 0.97s;
   -webkit-animation-delay: 0.97s;
   -moz-animation-delay: 0.97s;
   left: 16px;
   top: 0px;
}
.loader span.block-3 {
   animation-delay: 0.87s;
   -o-animation-delay: 0.87s;
   -ms-animation-delay: 0.87s;
   -webkit-animation-delay: 0.87s;
   -moz-animation-delay: 0.87s;
   left: 31px;
   top: 0px;
}
.loader span.block-4 {
   animation-delay: 0.78s;
   -o-animation-delay: 0.78s;
   -ms-animation-delay: 0.78s;
   -webkit-animation-delay: 0.78s;
   -moz-animation-delay: 0.78s;
   left: 47px;
   top: 0px;
}
.loader span.block-5 {
   animation-delay: 0.69s;
   -o-animation-delay: 0.69s;
   -ms-animation-delay: 0.69s;
   -webkit-animation-delay: 0.69s;
   -moz-animation-delay: 0.69s;
   left: 0px;
   top: 16px;
}
.loader span.block-6 {
   animation-delay: 0.6s;
   -o-animation-delay: 0.6s;
   -ms-animation-delay: 0.6s;
   -webkit-animation-delay: 0.6s;
   -moz-animation-delay: 0.6s;
   left: 16px;
   top: 16px;
}
.loader span.block-7 {
   animation-delay: 0.51s;
   -o-animation-delay: 0.51s;
   -ms-animation-delay: 0.51s;
   -webkit-animation-delay: 0.51s;
   -moz-animation-delay: 0.51s;
   left: 31px;
   top: 16px;
}
.loader span.block-8 {
   animation-delay: 0.41s;
   -o-animation-delay: 0.41s;
   -ms-animation-delay: 0.41s;
   -webkit-animation-delay: 0.41s;
   -moz-animation-delay: 0.41s;
   left: 47px;
   top: 16px;
}
.loader span.block-9 {
   animation-delay: 0.32s;
   -o-animation-delay: 0.32s;
   -ms-animation-delay: 0.32s;
   -webkit-animation-delay: 0.32s;
   -moz-animation-delay: 0.32s;
   left: 0px;
   top: 31px;
}
.loader span.block-10 {
   animation-delay: 0.23s;
   -o-animation-delay: 0.23s;
   -ms-animation-delay: 0.23s;
   -webkit-animation-delay: 0.23s;
   -moz-animation-delay: 0.23s;
   left: 16px;
   top: 31px;
}
.loader span.block-11 {
   animation-delay: 0.14s;
   -o-animation-delay: 0.14s;
   -ms-animation-delay: 0.14s;
   -webkit-animation-delay: 0.14s;
   -moz-animation-delay: 0.14s;
   left: 31px;
   top: 31px;
}
.loader span.block-12 {
   animation-delay: 0.05s;
   -o-animation-delay: 0.05s;
   -ms-animation-delay: 0.05s;
   -webkit-animation-delay: 0.05s;
   -moz-animation-delay: 0.05s;
   left: 47px;
   top: 31px;
}
.loader span.block-13 {
   animation-delay: -0.05s;
   -o-animation-delay: -0.05s;
   -ms-animation-delay: -0.05s;
   -webkit-animation-delay: -0.05s;
   -moz-animation-delay: -0.05s;
   left: 0px;
   top: 47px;
}
.loader span.block-14 {
   animation-delay: -0.14s;
   -o-animation-delay: -0.14s;
   -ms-animation-delay: -0.14s;
   -webkit-animation-delay: -0.14s;
   -moz-animation-delay: -0.14s;
   left: 16px;
   top: 47px;
}
.loader span.block-15 {
   animation-delay: -0.23s;
   -o-animation-delay: -0.23s;
   -ms-animation-delay: -0.23s;
   -webkit-animation-delay: -0.23s;
   -moz-animation-delay: -0.23s;
   left: 31px;
   top: 47px;
}
.loader span.block-16 {
   animation-delay: -0.32s;
   -o-animation-delay: -0.32s;
   -ms-animation-delay: -0.32s;
   -webkit-animation-delay: -0.32s;
   -moz-animation-delay: -0.32s;
   left: 47px;
   top: 47px;
}
@keyframes load {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-97px);
		-o-transform: translateY(-97px);
		transform: translateY(-97px);
	}
	15% {
		opacity: 0;
		-webkit-transform: translateY(-97px);
		-o-transform: translateY(-97px);
		transform: translateY(-97px);
	}
	30% {
		opacity: 1;
		-webkit-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0);
	}
	70% {
		opacity: 1;
		-webkit-transform: translateY(0);
		-o-transform: translateY(0);
		transform: translateY(0);
	}
	85% {
		opacity: 0;
		-webkit-transform: translateY(97px);
		-o-transform: translateY(97px);
		transform: translateY(97px);
	}
	100% {
		opacity: 0;
		-webkit-transform: translateY(97px);
		-o-transform: translateY(97px);
		transform: translateY(97px);
	}
}
@-o-keyframes load {
	0% {
		opacity: 0;
		-o-transform: translateY(-97px);
	}
	15% {
		opacity: 0;
		-o-transform: translateY(-97px);
	}
	30% {
		opacity: 1;
		-o-transform: translateY(0);
	}
	70% {
		opacity: 1;
		-o-transform: translateY(0);
	}
	85% {
		opacity: 0;
		-o-transform: translateY(97px);
	}
	100% {
		opacity: 0;
		-o-transform: translateY(97px);
	}
}
@-webkit-keyframes load {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-97px);
	}
	15% {
		opacity: 0;
		-webkit-transform: translateY(-97px);
	}
	30% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
	70% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
	85% {
		opacity: 0;
		-webkit-transform: translateY(97px);
	}
	100% {
		opacity: 0;
		-webkit-transform: translateY(97px);
	}
}


*/
/*-------------------------------*/
/*Loader ends*/
/*-------------------------------*/

@media screen and (max-width: 768px) {
  .container {
    max-width: 98%;
  }
}

/*Large devices (desktops, less than 1200px)*/
@media (max-width: 1200px) {
  html {
    font-size: 15px;
  }
}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 992px) {
  html {
    font-size: 14px;
  }
  h1 {
    font-size: 3.4rem;
    font-weight: 400;
  }
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1.35rem;
  }
  .heading-title > span {
    font-size: 1.5rem;
    margin-top: 40px;
  }
  p.title {
    font-size: 1.35em;
  }
  .counters .count_nums {
    font-size: 3rem;
  }
  .process-wrapp li p:last-child {
    font-size: 1.25rem;
  }
}

/* Medium devices (tablets, less than 992px)*/
@media (max-width: 450px) {
  html {
    font-size: 14px;
  }
  h1 {
    font-size: 2.8rem;
    font-weight: 400;
    line-height: 3.2rem;
  }
  h2 {
    font-size: 2.8rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.75rem;
  }
  h5 {
    font-size: 1.75rem;
  }
  p {
    font-size: 1.5rem;
  }
  .heading-title > span {
    font-size: 1.6rem;
    margin-top: 40px;
  }
  p.title {
    font-size: 1.75em;
  }
  .counters .count_nums {
    font-size: 3rem;
  }
  .process-wrapp li p:last-child {
    font-size: 1.5rem;
  }

  .margin_bottom {
    margin-bottom: 4rem;
  }
}

/*Small devices (landscape phones, less than 768px)*/
@media (max-width: 768px) {
  html {
    font-size: 13px;
  }
}
@media (max-width: 576px) {
  html {
    font-size: 12px;
  }
}

/*Bootstrap*/

.visible-xs {
  display: none !important;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
  .visible-xs {
    display: block !important;
  }
}

@media (max-width: 350px) {
  h1 {
    font-size: 2.2rem;
    line-height: 2.4rem;
  }
}

