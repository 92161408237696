.title {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.text {
  font-weight: 300;
  padding: 0 0 20px 0;
}

.containerMobile {
  width: 100%;
  padding: 15px;
}
