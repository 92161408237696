.currentCredits {
  font-weight: bold;
  font-size: 20px;
  color: black;
}

.availableCredits {
  font-weight: 300;
  font-size: 16px;
  color: black;
  margin-top: 10px;
}

.upperRow {
  border-bottom: 1px solid #7070703a;
}

.description {
  font-weight: 300;
  color: #212331;
  font-size: 16px;
}

.description {
  width: 60%;
  font-size: 16px;
}

.button {
  width: 40%;
}

p {
  width: 100%;
  font-size: 16px;
  margin: 0;
}

.downRow {
  padding: 20px 0 0 0;
}

@keyframes moveBox {
  0% {
    margin-top: 40px;
  }

  100% {
    margin-top: 50px;
  }
}

.box {
  margin-top: 40px;
  padding: 20px 15px;
  border: 1px solid #c6c6c6;
  transition: 1s ease-in;
  border-radius: 12px;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.box i {
  font-size: 20px;
  color: #e51d49;
}

.box h4 {
  padding-left: 10px;
}

.box:hover {
  animation-name: moveBox;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}
