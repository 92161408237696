.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 100px;
}

.header {
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 2em;
  border-radius: 3px;
  background-color: white;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.logo {
  width: 250px;
  margin: 0px auto 20px;
}

.error {
  font-family: "Open Sans", sans-serif;
  color: #cc0000;
}
