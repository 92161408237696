@keyframes menuMobileAnimation {
  0% {
    opacity: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}

@keyframes navOpacity {
  0% {
    height: 0px;
    opacity: 0;
  }

  100% {
    height: 70px;
    opacity: 1;
  }
}

html {
  scroll-behavior: smooth;
}

.nav {
  display: flex;
  flex-direction: column;
  height: 70px;
  align-content: center;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.left {
  cursor: pointer;
}

.fixedNav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  animation-name: navOpacity;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  z-index: 2;
}

.navrow {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}

.row {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  transition: 1s ease-in;
}

.menuMobile {
  margin-top: 100px;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-color: white;
  margin: auto;
  transition: 1s ease-in;
  animation-name: menuMobileAnimation;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  -webkit-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
}

.right {
  display: flex;
}

.menu {
  display: flex;
  list-style-type: none;
  color: black;
  font-size: 16px;
}

.menu a {
  color: black;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.menu a:hover {
  color: #2e9be2;
}

.menu:target {
  background-color: blue;
}

.burguer {
  color: #2e9be2;
  background-color: white;
  border: 1px solid #7070703a;
  height: 45px;
  width: 45px;
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .nav {
    padding: 15px;
    margin-top: 0;
    justify-content: unset;
  }

  .navrow {
    display: unset;
    width: 85%;
    transition: 1s ease-in;
    padding-bottom: 10px;
    z-index: 999;
    position: fixed;
  }

  .menu {
    display: flex;
    flex-direction: column;
  }
}
