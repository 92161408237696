.container {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  max-width: 600px;
}

.optionsList {
  margin: auto;
  padding: 10px;
  align-self: center;
  align-self: center;
  width: 50%;
}

.button {
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .header {
    width: 300px;
    flex-direction: column;
    justify-items: center;
    text-align: center;
  }
  .list {
    flex-direction: column;
  }

  .optionsList {
    display: flex;
    flex-direction: row;
    width: 50%;
  }
}
