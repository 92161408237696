.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 100px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  border-radius: 3px;
  background-color: white;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.form {
  display: flex;
  min-width: 580px;
  flex-direction: column;
  padding: 2em;
  border-radius: 3px;
  background-color: white;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.logo {
  width: 250px;
  margin: 0px auto 20px;
}

@media only screen and (max-width: 600px) {
  .form {
    min-width: unset;
  }
}
