.modalButtons {
  display: flex;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.modal {
  z-index: 999;
  background-color: white;
  padding: 20px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  border: 1px solid #707070;
  border-radius: 5px;
}

.close {
  width: 100%;
  text-align: right;
  top: 10px;
  right: 10px;
  position: absolute;
}

.close i {
  color: rgb(97, 97, 97);
  padding: 10px 12px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.1s ease-in;
}

.close i:hover {
  color: rgb(70, 69, 69);
  background-color: rgb(231, 231, 231);
  padding: 12px 14px;
  border-radius: 50%;
  cursor: pointer;
}

@media (max-width: 600px) {
  .modal {
    max-height: 600px;
    overflow-y: unset;
  }
}
