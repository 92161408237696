.fluid {
  background-color: #f7f9ff;
  width: 100%;
}

.images {
  filter: grayscale(100%);
  padding-top: 20px;
  align-self: center;
  cursor: grab;
  transition: 0.2s ease-in;
  padding: 20px 0px 0px 30px;
}

.logo {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-self: center;
  height: 180px;
  width: 180px;
}

/* .images:hover {
  filter: grayscale(0%);
} */
