.detail {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.item {
  display: flex;
  margin-bottom: 15px;
  justify-content: flex-start;
  color: black;
}

.item i {
  margin-right: 15px;
  font-size: 36px;
  text-align: center;
  color: #212331;
}

.item p {
  margin-right: 35px;
  font-weight: 300;
  font-size: 16px;
  color: #212331;
}

@media only screen and (max-width: 600px) {
}
